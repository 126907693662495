@import "../../../commonStyles/Common.scss";
.press-release-section{
    background: linear-gradient(120deg, $primary-bgColor 35%, #fff 35%);
    .pressLeftDiv{
        padding-top: 50px;
        h3{
            color: #fff;
            margin-bottom: 60px;
            font-weight: 600!important;
        }
        .hexa-secondary-btn{
            margin-top: 12px;
        }
    }
    .slick-slider{
        margin-right: 100px;
        .slick-list{
            .slick-track{
                .slick-slide{
                    margin: 0;
                    .pressOuterCard{
                        padding: 16px 10px 30px;
                        .pressCard{
                            background-color: #fff;
                            padding: 7px;
                            border-radius: 10px;
                            // box-shadow: -2px -1px 17px 4px rgba(229, 229, 229, 0.74);
                            // box-shadow: -2px -1px 17px 4px #00000029;
                            // box-shadow: 0px 10px 40px #83838329;
                            box-shadow: 0px 7px 11px 4px #83838329;
                            .img{
                                img{
                                    width: 100%;
                                }
                            }
                            .content{
                                padding: 16px 6px 0;
                                p{
                                    font-size: 13px;
                                    margin-bottom: 24px;
                                }
                                .hexa-default-btn{
                                    border-top: 1px solid #efefef;
                                    width: 100%;
                                    p{
                                        display: flex;
                                        text-align: left;
                                        margin-bottom: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
    .slick-arrow{
        border: 1px solid $secondary-color;
        border-radius: 50%;
        z-index: 9;
        &::before{
            color: $secondary-color;
        }
        &.slick-prev, &.slick-next {
            height: 40px;
            width: 40px;
            &::before{
                content: "";
            }
        }
        &.slick-prev {
            top: 40%;
            left: 101%;
            display: flex;
            justify-content: center;
            align-items: center;
            .anticon{
                svg{
                    width: 20px;
                    height: 20px;
                    color: $secondary-color;
                }
            }
        }
        &.slick-next {
            top: 60%;
            right: -6.4%;
            display: flex;
            justify-content: center;
            align-items: center;
            .anticon{
                svg{
                    width: 20px;
                    height: 20px;
                    color: $secondary-color;
                }
            }
        }
    }
}