@import "../../commonStyles/Common.scss";

.contact-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .form-section-main-container {
        width: 100%;
        background-color: $tertiary-bgColor;
        .form-wrapper {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            padding: 3em;
            border-radius: 20px;
            background-color: #fff;
            box-shadow: 0px 0px 12px 0px rgba(0, 0, 0, 0.15);

            .form-elements-wrapper {
                padding: 10px;

                h2 {
                    color: $primary-color;
                    font-weight: bold;
                    margin-bottom: 0.2em;
                }

                .ant-input {
                    background-color: #fafafa;
                    padding: 6px 11px;
                }
                .text-area{
                    min-height: 10em;
                }

                .submit-btn {
                    background-color: $primary-bgColor;
                    padding: 1.5em;
                    @include flexProp(center);
                    border-radius: 7px;
                }
                .contact-details-wrapper{
                    margin-left: 2em;
                    .contact-details {
                        margin-bottom: 2em;
    
                        h4 {
                            color: $primary-color;
                            font-weight: bold;
                            font-size: 1.25rem;
                        }
    
                        .details {
                            @include flexProp(flex-start);
                            margin-bottom: 1em;
                            color: $primary-color;
                            letter-spacing: 0.5px;
                        }
                    }
                }
            }
        }
    }

    .contact-C {
        @include flexProp(center);
        flex-wrap: wrap;
        width: 100%;

        .card {
            padding: 2em;
            background-color: $primary-bgColor;
            margin-right: 1em;
            border-radius: 20px;

            .card-content {
                min-height: 17em;
                max-height: max-content;
            }

            .card-content-heading {
                color: #fff;
            }
        }

        .details {
            @include flexProp(flex-start);
            margin-bottom: 1em;
            color: #fff;
            letter-spacing: 0.5px;
        }
    }
}