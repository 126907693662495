@import '../../../commonStyles/Common.scss';

.css-main-container {
   

    h3,
    h4 {
        font-weight: 700;
        color: $primary-color;
    }

    h4 {
        font-size: larger;
    }

    .section-A {
        height: max-content;
        .outer-grid {
            background-color: transparent;
            background-image: linear-gradient(90deg, #9D222200 76%, #F1F8FF 60%);
            opacity: 1;

            .inner-grid{
                .inner-grid-text-section{
                    padding-top: 3rem;
                }
                .image-section{
                    img{
                        padding-top: 4.5rem;
                    }
                }
            }
        }
    }

    .section-B {
        .outer-grid {
            background-color: transparent;
            background-image: linear-gradient(90deg, #EEF7F2 24%, #F9F9F9 19%);
            opacity: 1;
        }
    }

    .section-c {
        .outer-grid {
            background-color: transparent;
            background-image: linear-gradient(90deg, #DD1A1A00 65%, #FDFBEF 60%);
            opacity: 1;

            .inner-grid {
                // .inner-grid-text-section{
                //     width: 65%;
                //     position: relative;
                // }
                .image-section {
                    // position: absolute;
                    img {
                        padding-left: 2rem;
                        width: 100% !important;
                        height: 100% !important
                    }
                }
            }

        }

        .inner-grid-text-section {
            padding-top: 3rem;
        }
    }

    .css-section {
        .outer-grid {
            padding-top: 2rem;
            .inner-grid {
                display: flex;
                flex-direction: row;

                .inner-grid-text-section {
                    padding-left: 12px;

                    h4 {
                        font-size: 20px;
                    }
                }

                .boxes-grid {
                    box-sizing: border-box;
                    border: 1px solid #f5f5f5;
                }
            }

            @media (max-width: 1100px) {
                .inner-grid {
                    flex-wrap: wrap;
                    flex-direction: column;
                }
            }

            .heading {
                padding-top: 2rem;
            }

            li {
                display: flex;
                align-items: center;
                padding-right: 2px;
                padding-left: 2px;

                p {
                    color: #393A56;
                    margin: 0px;
                    font-size: 14px;
                }
            }
            @media (max-width: 1100px) {
                .image-section{
                    display: none;
                }
            }

            .image-section {
                margin-top: 3rem;

                img {
                    width: 450px;
                    height: 470px;
                }
            }

            .card-left {
                height: 135px;
                margin-top: 10px;

                .img-container {
                    background-color: #eef5ff;
                    border-radius: 34px 34px 34px 34px;
                }

                img {
                    width: 40px;
                }

                h4 {
                    font-weight: 600 !important;
                    font-size: 18px !important;
                }

                p {
                    font-size: 14px !important;
                    margin-top: -10px;
                }
            }
        }
        
    }
    
    
}