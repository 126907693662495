.trustBy{
    background-color: #FFF;
    width: 100%;
    .heading{
        text-align: center;
        h2{
            color: #000;
            margin-bottom: 10px;
            font-weight: 600 !important;
            font-size: 28px;
        }
        p{
            width: 75%;
            margin: 0 auto;
        }
    }
    .trustBy-inner{
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        .trustImg{
            width: 75%;
            margin: 0 auto;
            -webkit-filter: grayscale(100%); /*to make images black and white*/
            filter: grayscale(100%);           
            opacity: 0.5;
        &:hover{
            -webkit-filter: grayscale(0%); /*to make images black and white*/
            filter: grayscale(0%);  
            opacity: 1;
        }
        }
    }
}