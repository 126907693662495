/*@...COLORS VARIABLES...@*/ 
$primary-color:#242a56;
$primary-bgColor:#02174E;
$secondary-color:#1B78FE;//#43BAFF;
$secondary-bgColor:#092365;
$tertiary-bgColor:#F1F8FF;
$blue-Color:#1B78FE;
$primary-bgOverlay:#0C2461;

/*@...MARGIN PADDING VARIABLES...@*/
$S-M:0.7em; //Sections margin
$S-MT :4em; // Sections margin top
$S-MB:4em; // Sections margin bottom
$SI-MT:4em; // Sections Inner margin Top
$SI-MB:4em; // Sections Inner margin bottom
$SI-ML:4em; // Sections Inner margin Left
$SI-MR:4em; // Sections Inner margin Right

$S-P:0.7em;  // Sections padding
$S-PT :3em; // Sections padding top
$S-PB:3em;  // Sections margin bottom
$SI-P:1em;  // Sections inner padding
$SI-PT:3em; // Sections Inner padding Top
$SI-PB:3em; // Sections Inner padding bottom
$SI-PL:3em; // Sections Inner padding Left
$SI-PR:3em; // Sections Inner padding Right
$SI-PX:0 3em; // Sections Inner padding Right and Left

$h1:2.7rem;
$h2:2.2rem;
$h3:1.8rem;
$h4:1.5rem;


/*@...common font sizes and html body behaviour...@*/
$normal-text-font:1rem;

html {  
    scroll-behavior: smooth;  
}
body{
  font-family: 'Poppins', Sans-serif !important;
}
h1{
    font-size: 2.7rem;
}
h2{
  font-size: 2.2rem;
}
h3{
    font-size: 1.8rem;
}
h4{
   font-size: 1.5rem;
}
h1,h2,h3,h4,h5{
    line-height: 1.2;
    font-weight: 600;
    margin-bottom: 0;
}

section{
    width: 100%;
}
// custom container css
.container{
    position:relative;
    margin-left:auto;
    margin-right:auto;
    padding-right:15px;
    padding-left:15px
  }
  @media (min-width: 476px){
    .container{
      width:100%
    }
  }
  @media (min-width: 768px){
    .container{
      width:720px;
      max-width:100%
    }
  }
  @media (min-width: 992px){
    .container{
      width:960px;
      max-width:100%
    }
  }
  @media (min-width: 1200px){
    .container{
      width:1140px;
      max-width:100%
    }
  }
  @media (min-width: 1400px){
    .container{
      width:1340px;
      max-width:100%
    }
  }
  // custom container-fluid css
  .container-fluid{
    position:relative;
    margin-left:auto;
    margin-right:auto;
    width:1340px;
    max-width:100%
  }


/*@...HEXA BTN's...@*/
.hexa-primary-btn{
    background: $blue-Color;
    width: 200px;
    border-radius: 25px;
    margin: 0 auto;
    text-align: center;
    p{
        color: #fff;
        display: block;
        padding: 7px 0;
        margin-bottom: 0;
        cursor: pointer;
        img{
            width: 30px;
        }
    }
}
.hexa-secondary-btn{
    border: 2px solid $blue-Color;
    display: inline-block;
    margin: 0 auto;
    border-radius: 25px;
    width: 135px;
    text-align: center;
    p{
        color: $blue-Color;
        display: block;
        padding: 7px 0;
        margin-bottom: 0;
        cursor: pointer;
        img{
            width: 30px;
        }
    }
}
.hexa-default-btn{
    border: none;
    display: inline-block;
    margin: 0 auto;
    text-align: center;
    p{
        color: $blue-Color;
        display: block;
        padding: 7px 0;
        margin-bottom: 0;
        cursor: pointer;
        img{
            width: 30px;
        }
    }
}
// .hexa-primary-rounded-btn{

// }
// .hexa-default-rounded-btn{

// }

/*@...classes...@*/
.element-widget-wrap{
    padding: 10px;
    line-height: 1.6;
}
.Section{
    padding: 4em 0;
}


.element-inner-section{
    align-items: center;
}
.paddingTopSection{
  padding-top: 70px;
}