@import "../../../../commonStyles/Common.scss";

.cloud-migration-solutions {

    .hexa-primary-btn {
        width: fit-content;
        margin-top: 4rem;

        p {
            padding: 0.5rem 0.6rem;
            font-weight: 600;

            img {
                width: 40px;
            }
        }
    }

    .devops-consulting-service-section-B {
        p {
            width: 80%;
            font-style: italic;
            margin-bottom: 3em;
        }

        .inn-container {
            justify-content: center;
            align-items: center;
            width: 90%;

        }

        .section-B-left {
            p {
                font-style: normal;
                width: 100%;
            }

            h2 {
                color: $primary-color;
                margin-bottom: 10px;
                font-weight: 700 !important;
            }

            .hexa-secondary-btn {
                border: none;
                width: fit-content;

                p {
                    font-weight: 500;
                    font-size: 17px;

                    img {
                        width: 40px;
                    }
                }
            }
        }

    }

    .devops-consulting-service-section-C {
        background-color: #F1F8FF;

        .card {
            align-items: center;
            box-shadow: 0 7px 21px 2px #ccc;
            background: #fff;
            padding: 50px;

            .section-C-left,
            .section-C-right,
            .section-C-middle {
                padding: 5px;
            }

            @media (min-width: 900px) {
                .section-C-left {
                    .image-section {
                        width: 100%;

                        img {
                            width: 90%;
                        }
                    }
                }
            }


            .section-C-middle {
                padding-left: 16px;

                .heading {
                    margin-bottom: 3em;

                    h4 {
                        font-size: 18px;
                        font-weight: 500 !important;
                    }

                    h3 {
                        color: #000;
                        font-weight: 700;
                        margin-bottom: 10px;
                    }
                }

                .text-section {
                    font-size: $normal-text-font;
                    color: $primary-color;

                    p {
                        width: 110%;
                        margin-bottom: 0;
                        font-weight: 400;
                    }
                }
            }

            @media (min-width: 900px) {
                .section-C-right {
                    margin-top: -30px;

                    .hexa-primary-btn {
                        margin-top: -10px;
                    }
                }
            }
        }
    }

    .devops-consulting-service-section-D {
        background-color: $primary-bgColor;

        .heading-wrapper {
            text-align: center;
            margin-bottom: 30px;

            h4 {

                font-size: 1.3rem;
                font-weight: 400;
                color: #fff;
                margin-bottom: 15px;
            }

            h2 {
                font-weight: 700 !important;
                color: #fff;
            }
        }

        .service-wrapper-card {
            .MuiGrid-item {
                &:nth-child(1) {
                    border-right: 1px solid #053C85;
                    border-bottom: 1px solid #053C85;
                }

                &:nth-child(2) {
                    border-bottom: 1px solid #053C85;
                }

                &:nth-child(3) {
                    border-right: 1px solid #053C85;
                }

                @media (min-width: 900px) {
                    &:nth-child(2) {
                        padding-left: 2rem;
                    }

                    &:nth-child(4) {
                        padding-left: 2rem;
                    }

                }

                @media screen and (max-width: 899px) {
                    &:nth-child(1) {
                        border: none;
                    }

                    &:nth-child(2) {
                        border: none;
                    }

                    &:nth-child(3) {
                        border: none;
                    }
                }
            }

            .card {
                color: #fff;
                padding: 2em;

                h4 {
                    font-weight: 700 !important;
                    color: #fff;
                    margin: 15px 0 10px;
                }

                p {
                    margin-top: -7px;
                    width: 95%;
                }

                img {
                    width: 50px;
                }

                .filter-green {
                    filter: invert(48%) sepia(79%) saturate(2476%) hue-rotate(86deg) brightness(90%) contrast(119%);
                }
            }
        }
    }

    .devops-consulting-service-section-E {
        background-color: #fff;

        .heading-wrapper {
            h2 {
                font-weight: 700 !important;
                color: $primary-color;

                span {
                    color: $blue-Color;
                    display: block;
                }
            }

            h4 {
                font-weight: 700 !important;
                font-size: 1.2rem;
                margin: 10px 0;
                color: $primary-bgColor;
            }

            p {
                font-size: 16px;
                word-spacing: 0.03rem;
            }
        }

        .service-wrapper-card {
            border-radius: 10px;

            .card-container {
                background-color: #fff;
                padding: 2em;
                margin: 8px;
                border-radius: 12px;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);

                h4 {
                    font-size: 18px;
                }

                p {
                    font-size: 14px;
                    margin-top: -5px;
                }

                .card {
                    margin-bottom: 2px;

                    @media screen and (max-width: 899px) {
                        text-align: center;
                    }

                    .icon-wrapper {
                        .img-container {
                            img {
                                width: 50px;
                            }
                        }

                        @media screen and (max-width: 899px) {
                            display: flex;
                            justify-content: center;
                        }
                    }
                }
            }

            .card-container {
                min-height: 14em;
                max-height: 14em;
            }

            @media (min-width: 900px) and (max-width: 1000px) {
                .card-container {
                    min-height: 22em;
                    max-height: 22em;
                }
            }
        }
    }


    .devops-consulting-service-section-G {
        background-color: #F1F8FF;
        width: 100%;

        .heading-wrapper {
            text-align: center;
            margin-bottom: 32px;

            h2 {
                font-weight: 600 !important;
                margin-bottom: 10px;
            }
        }

        .counterCard {
            width: 75%;
            margin: 0 auto 32px;

            @media screen and (max-width: 899px) {
                width: 100%;
            }

            @media (min-width: 900px) {
                .counter-widget-main-container {
                    width: 125%;
                    padding-right: 70px;
                    height: 90%;
                }
            }

            .counter-widget-main-container {
                .counter-box {
                    background: #fff;
                    border-radius: 12px;
                    box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
                    padding: 2em 1em 3em;
                    margin: 10px;
                    line-height: normal;

                    &::after {
                        background: transparent;
                        height: 0;
                    }

                    .counter-box-elements {
                        text-align: center;
                        width: 100%;

                        .number-counter-wrapper {
                            margin-top: 0;
                            color: $blue-Color;
                        }

                        .counter-title {
                            font-size: 16px;
                            color: #000;
                            padding: 0;
                        }
                    }
                }
            }
        }

    }
}