@import '../../../commonStyles/Common.scss';

.main-container {
    .pr-section {
        background: url('../../../../assets/Resources/press-release/pattern-bg-new.svg');
        background-repeat: no-repeat;
        @media (min-width: 1200px) {
            .pr-card {
                min-height: 800px;
            }

        }

        .pr-card {
            padding: 2rem 2rem 2.5rem 2rem;

            .content {
                h2 {
                    color: $primary-color;
                    font-weight: 600;
                    padding-right: 2rem;
                }
            }

            .press-shape-1 {
                position: relative;
                margin-bottom: -20px;
                margin-left: 5px;
            }

            .image {
                position: relative;
                display: flex;
                justify-content: center;
                align-items: center;
                // width: 42%;
                box-shadow: 0px 10px 20px 0px #999999;
                border-radius: 12px;

                img {
                    padding: 1rem;
                    width: 470px;
                    height: 300px;
                }
            }

            .press-shape-2 {
                // position: absolute;
                margin-top: -130px;
                display: flex;
                justify-content: flex-end;
            }

            .talk-to-epert {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 2rem;

                h5 {
                    color: #43BAFF;
                    margin-top: 9px;
                    margin-right: 10px;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }
}