@import "../../commonStyles/Common.scss";

.card-container {
    .card {
        margin-bottom: 11px;

        .card-content {
            min-height: 13em;
            max-height: max-content;
            overflow-y: auto;
            color: $primary-color;

            .card-content-heading{
                font-weight: bold;
                h4{
                    color: $primary-color;
                    font-weight: bold;
                }
            }
            .card-content-action-heading{
                h4{
                    font-size: 18px;
                    color: $primary-color;
                    font-weight: bold;
                    cursor: pointer;
                }
            }

            p {
                font-size: $normal-text-font;
                font-weight: 300;
                line-height: 1.6em;
            }
        }
        @media only screen and (max-width: 700px) {
            .card-content{
                min-height: fit-content !important;
                max-height: fit-content !important;
            }
          }

        .icon {
            height: 60px;
            width: 60px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #EEF5FF;
            border-radius: 50%;
        }
    }
}


.card-withLeft-header-wrapper-element{
    margin-bottom: 1em;
    padding: 1em;
    .card{
        display: flex;
        flex-wrap: initial;
    }
    .icon-wrapper{
        .MuiCardHeader-root{
            padding: 0;
        }
        .img-container{
            text-align: center;
            margin-right: 15px;
        }
    }
    p{
        font-size: 13px !important;
        color: $primary-color;
    }
}
.card-chip-container{
    .card-chip-elementory-column{
        text-align: center;
        margin: 0.9%;
        box-shadow: 0 14px 22px -1px #ededed;
        border-radius: 6px;
        padding: 20px;
        img{
            width: 7em;
        }
        p{
            font-size: 12px;
            font-weight: bold;
            color: $primary-color;
        }
    }
}