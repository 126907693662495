@import '../../../../commonStyles/Common.scss';

.wwn-main-container-section {
    .text-icon-section {
        display: flex;
        flex-direction: row;

        .icon {
            padding-right: 1rem;
        }
    }

    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .wwn-section-A {
        background-color: $tertiary-bgColor;

        .cards-container {
            display: flex;
            flex-wrap: wrap;
            padding: 3.5rem;

            .css-bhp9pd-MuiPaper-root-MuiCard-root {
                box-shadow: inset 0px 10px 20px 0px rgba(0, 0, 0, 0.18);
            }

            .text-section {
                h4 {
                    font-size: 1.2rem;
                }
            }

            .btn-container {
                @include flexProp(center);

                .btn {
                    @include flexProp(center);
                    height: 52px;
                    border-radius: 40px;

                    a {
                        color: #1B78FE;
                        margin-right: 5px;
                    }
                }
            }
        }
    }

    .wwn-section-B {
        display: flex;
        flex-wrap: wrap;

        h2 {
            color: #000000;
            font-weight: 10px;
        }
    }

    .wwn-section-C {
        display: flex;
        flex-wrap: wrap;
        background: $primary-bgColor;

        .container {
            h4 {
                font-size: 22px;
            }
        }

        .heading {

            h2,
            h4,
            h3 {
                color: #ffffff;
            }
        }

        .text-section {
            color: #ffffff;
        }
    }

    .wwn-section-D {
        background-color: $tertiary-bgColor;

        .heading {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .team-card-container {
            .heading {
                display: flex;
                justify-content: flex-start;
                padding-top: 2rem;
            }

            .text-icon-section {
                padding: 0.7rem 1rem 0.5rem 0.7rem;

                .icon {
                    img {
                        width: 4.5rem;
                    }
                }
            }
        }
    }
}