@import "../../commonStyles/Common.scss";

.footer-section {
    // margin-top: $S-MT;
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }
    .footer-top-section-elements-row {
        justify-content: center;
        align-items: center;
        background-color: $primary-bgColor;
        margin-bottom: 30px;
        padding: 50px 15px 55px 15px;
        width: 100%;
        .footer-top-section-text-elements {
            text-align: center;
            h4{
                font-size: 18px;
                font-weight: bold;
                color: #43BAFF;
                font-weight: 400 !important;
            }
            h2 {
                color: #fff;
                font-size: 32px;
                font-weight: 400 !important;
            }

            p {
                color: #FFFFFF;
                font-size: 26px;
                line-height: 30px;
                color: #4169e1;
                img{
                    width:55px
                }
            }
        }

        .footer-top-section-btn-elements {
            @include flexProp(center);
            .btn {
                @include flexProp(center);
                padding: 1.5em 4em 1.5em 4em;
                background-color: #FFFFFF;
                border-radius: 47px 47px 47px 47px;
                font-weight: bold;
                color: $primary-color;
            }
        }
    }

    .footer-section-B {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 30px;

        .titles{ 
            margin: 2px 2px 10px 0px;
            padding-bottom: 5px;
            font-weight: bold;
            color: $primary-color;
            font-size: 1.1rem;
            border-bottom: 2px solid #efefef;
            position: relative;
            &::after{
                position: absolute;
                content: "";
                width: 50px;
                height: 2px;
                background-color: $secondary-color;
                bottom: -2px;
                left: 0;
            }
        }
        .section-B-Service-items,
        .footer-section-B-products-items {
            display: flex;

            a {
                color: $primary-color;
                font-size: 14px;
                font-weight: normal;
                padding: 8px 0;
                display: block;
            }

            a:hover {
                color: #4169e1;
            }

            ul {
                margin: 5px 10px 5px 0;
            }
        }

        .footer-section-B-connect-icons {
            @include flexProp(space-between);
            flex-wrap: wrap;
            margin: 30px 0 20px;
            img{
                width: 35px;
                transition: 100ms ease-in-out;
                cursor: pointer;
                &:hover{
                    transform: scale(1.1);
                }
            }
        }
        .social-Icons{
            p{
                display: flex;
                font-weight: bold;
                font-size: 14px;
                cursor: pointer;
                &:hover{
                    color: #1B78FE;
                }
                img{
                    margin-right: 6px;
                    width: 20px
                }
            }
        }
    }

    .footer-section-C {
        border-top: 1px solid #EEEEEE;
        border-bottom: 1px solid #EEEEEE;
        padding: 40px 0px 20px;
        background-color: #f6f6f6;
        .footer-section-C-row {
            @include flexProp(space-between);
            flex-wrap: wrap;
            .address-container {
                h3{
                    font-size: 14px;
                    color: $primary-color;
                }
                p {
                    color: $primary-color;
                    font-size: 14px;
                    margin-bottom: 0;
                    span{
                        color: #000;
                    }
                }
            }

            .subscribe-text-container {
                p{
                    font-size: 14px;
                    color: $secondary-color;
                    margin-bottom: 8px;
                }
                h2 {
                    margin-bottom: 0px;
                    color: $primary-color;
                    line-height: 30px
                }
            }

            .input-btn-container {
                @include flexProp(center);

                .cust-input {
                    border: none;
                    border-bottom: 2px solid $primary-color  !important;
                    background: none;
                    font-size: 15px;
                    // margin-bottom: 22px;
                    margin-right: 14px;
                }

                .cust-input:hover,
                .cust-input:focus {
                    outline: none !important;
                }
            }
        }

    }

    .footer-section-D {
        color: $primary-color;
        background-color: #fff;
        .footer-section-D-row {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            padding-bottom: 10px;
            .copy-right-container {
                padding: 25px 0;
                p{
                    color: $primary-color;
                    font-size: 14px;
                }
                .terms-item {
                    .list-item {
                        display: flex;
                        margin-bottom: 0;

                        a {
                            color: $primary-color;
                        }
                        li{
                            margin-right: 10px;
                            padding-right: 10px;
                            position: relative;
                            font-size: 14px;
                        }
                        li:not(:last-child)::after {
                            content: '';
                            position: absolute;
                            height: 50%;
                            width: 2px;
                            top: 50%;
                            transform: translateY(-50%);
                            background: #ddd;
                            font-size: 11px;
                            right: 0px;

                        }

                        p {
                            padding-left: 10px;
                            padding-right: 10px;
                            font-size: 14px;
                        }

                    }
                }
            }
            .copy-right-images-container{
                @include flexProp(center);
                img{
                    margin-right: 1em;
                }
            }
        }
    }
}