.servicePartner {
    width: 100%;
    background-color: #fff;

    .heading {
        text-align: center;

        h2 {
            font-size: 1.8rem;
            color: #000;
            font-weight: 600 !important;
            margin-bottom: 10px;
        }

        p {
            width: 75%;
            opacity: 1;
            margin: 0 auto;
        }
    }

    .servicePartner-inner {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        width: 70%;
        margin: 30px auto 0;

        @media screen and (max-width: 991px) {
            width: 100%;
        }

        .trustImg {
            box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
            border-radius: 10px;
            text-align: center;
            background: #fff;
            margin: 10px;
            width: 90%;
            height: 175px;
            display: flex;

            img {
                width: 70%;
                margin: auto;
            }
        }
    }
}