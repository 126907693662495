@import '../../../commonStyles/Common.scss';

.tech-stack-main-container {
    h4 {
        font-weight: 700;
        font-size: larger;
        color: $primary-color;
    }

    h3 {
        color: $primary-color;
        font-weight: 700;
    }

    .tech-section-B,
    .tech-section-D {

        .card-styling-B {
            .heading {
                h3 {
                    padding-top: 1em;
                    padding-bottom: 0.5em;
                }
            }
        }
    }

    .tech-section-B,
    .tech-section-C,
    .tech-section-D {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        background-color: #F2F5FF;

        .container {
            .image-section {
                display: flex;
                justify-content: center;
                align-items: center;

                img {
                    width: 200px;
                    height: 200px;
                }
            }

            .heading {
                display: flex;
                justify-content: center;
                align-items: center;
            }

            @media (max-width: 1000px) {
                .card-styling-B {
                    min-height: 690px;
                    max-height: 700px;
                }
            }

            @media (min-width: 1000px) {
                .card-styling-B{
                    min-height: 660px;
                    max-height: 700px;
                }
            }
        }

        .card-styling-B {
            border-radius: 15px;

            .main-container {
                .image-section {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                }
            }

            .inner-card-padding {
                justify-content: space-evenly;
            }
        }
    }

    .tech-section-C {
        background-color: #ffffff;

        .card-styling-B {
            background: #F2F5FF;

            .main-container {
                background-color: #F2F5FF;
            }

            padding: 2em;
        }
    }

    .tech-section-D {
        .card-styling-B {
            padding: 0.7rem;
            padding-left: 2em;
        }
    }
}