@import '../../../commonStyles/Common.scss';

.security-main-container {

    h3,
    h2 {
        color: #ffffff;
    }

    li {
        color: #FFFFFF;

        h3 {
            font-size: 20px;
        }
    }

    .security-section-B {
        background-color: #FFFFFF;

        .image-card {
            border-radius: 22px;
            min-height: 250px;
            max-height: 250px;
            background: url("https://hexaviewtech.com/wp-content/uploads/2020/12/analytics-information-innovation-3088958.jpg");
            background-attachment: fixed;

            display: flex;
            justify-content: center;
            align-items: center;

            .heading {
                h2 {
                    font-weight: 900;
                }
            }

        }

        .card-styling {
            border-radius: 8px;
            background-color: #F2F5FF;
            min-height: 250px;
            max-height: 250px;
            box-shadow: 0px 10px 35px -20px rgba(0, 0, 0, 0.16);

            .text-icon-section {
                .icon {
                    img {
                        width: 3rem;
                    }
                }

                h4 {
                    font-size: 1.25rem;
                    font-weight: 700 !important; 
                    color: $primary-color;
                }
            }

            .heading {
                h3 {
                    font-weight: 100;
                    color: $primary-color;
                }
            }
        }

        .image-card {
            .heading {
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .text-icon-section {
            display: flex;
            flex-direction: row;
            padding: 1.5rem;

            .icon {
                padding-right: 1rem;
            }
        }
    }

    .security-section-C {
        background: url("https://hexaviewtech.com/wp-content/uploads/2020/12/office-desk-smartphone-865091.jpg");
        background-attachment: fixed;
        position: relative;
        background-color: $primary-bgColor;

        .image-background-overlay {
            background: $primary-bgOverlay;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.7;
        }

        .subSection-C1 {
            h4 {
                color: #ffffff;
            }
        }
    }

    .subSection-C2 {
        // margin-top: $S-MT ;
        background-color: #0C2461E0;

        .heading {
            display: flex;
            justify-content: center;
            align-items: center;
            padding-bottom: 1rem;
        }

        .card-styling-C2 {
            background-color: #0C2461;
            border-radius: 8px;
            min-height: 330px;
            max-height: 330px;
            padding: 2rem;

            .heading {
                h4 {
                    color: #ffffff; 
                }
            }

            .text-section {
                p {
                    color: #ffffff;
                }
            }

            .text-icon-section {
                display: flex;
                flex-direction: column;

                h4 {
                    font-size: 1.8rem;
                }

                .icon {
                    img {
                        width: 4.5rem;
                    }
                }
            }
        }
    }

    .security-section-D {
        .heading {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}