@import "../../../../commonStyles/Common.scss";

.bd-services-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .bd-services-B {
        background-color: $tertiary-bgColor;

        .image-wrapper {
            margin-bottom: 3em;
        }

        .hexa-primary-btn {
            width: 195px;
            margin: 0;
            p{
                padding: 14px;
            }
        }

        .text-wrapper {
            h2 {
                color: $primary-color;
                font-weight: bold;

                span {
                    color: $secondary-color;
                }
            }

            .text-sextion {
                p{
                    color: $primary-color;
                    font-size: $normal-text-font
                }
            }
        }

        .big-data-service-wrapper {
            @include flexProp(space-between);
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 1em;

            .card-chip-container .card-chip-elementory-column {
                margin: 5.9%;
                padding: 20px;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);

                img {
                    width: 3em;
                    height: 3em;
                }
                p{
                    font-weight: 600;
                }
            }
        }

    }

    .bd-services-C {
        width: 100%;

        .heading {
            h2 {
                color: $primary-color;
                font-weight: bold;
                text-align: center;

                span {
                    color: $secondary-color;
                }
            }
        }

        .cards-wrapper {
            @include flexProp(center);
            flex-wrap: wrap;
            width: 100%;

            .card-withLeft-header-wrapper-element {
                margin-bottom: 2em;
                margin-right: 1em;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
                width: 48%;
                border-radius: 15px;
            }

            .card-container .card .card-content {
                min-height: 9em;
                max-height: 10em;
            }

            @media only screen and (max-width: 700px) {
                .card-withLeft-header-wrapper-element {
                    width: 100%;
                }
            }
        }
        .hexa-primary-btn {
            p{
                padding: 14px;
            }
        }
    }

    .inner-section {
        .section-A .image-elements-section .element-image-text-wrapper .text-section-a {
            font-size: 17px;
        }
    }

    .bd-services-D {
        width: 100%;

        .heading-wrapper {
            h3 {
                color: $primary-color;
                font-weight: bold;
                text-align: center;

                span {
                    color: $secondary-color;
                }
            }
        }

        .img-container {
            @include flexProp(center);
            flex-wrap: wrap;
        }
    }
}