@import "../../../../commonStyles/Common.scss";
.cloud-migration-solutions {
    // overflow: auto;
        .bigBadge{
            background: #F1F8FF;
        padding: 4px 20px;
        border-radius: 25px;
        display: inline-block;
        margin-bottom: 24px;
        p{
            font-size: 16px;
            margin-bottom: 0;
            font-weight: bold;
        }
    }
    .governance-security-service-section-B{
        .governance-security-service-section-left{
            .heading{
                h4{
                    margin-bottom: 0;
                }
                h2{
                    color: $blue-Color;
                    margin: 6px 0 10px;
                }
            }
            .text-section{
                .check-marks{
                    ul{
                        margin-bottom: 0;
                        li{
                            display: flex;
                            align-items: center;
                            margin: 8px 0;
                            .icon{
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            .hexa-default-btn{
                margin-left: 0;
                margin-top: 16px;
            }
        }
    }

    .governance-security-service-section-C{
        background-color: #F1F8FF;
        .heading-wrapper{
            h2{
                font-weight: bold;
                span{
                    color: $blue-Color;
                    display: block;
                }
            }
            h4{
                font-size: 18px;
                font-weight: bold;
            }
            .bigBadge{
                background: #E8EEF6;
            }
        }
        .service-wrapper-card{
            border-radius: 10px;
            .outer-card{
                border-radius: 12px;
                &:nth-child(3){
                    background-color: $blue-Color;
                    h4{
                        text-decoration: underline;
                    }
                    h4, p{
                        color: #fff;
                    }
                }
                .card{
                    padding: 0.8em;
                    h4{
                        font-size: 18px;
                        font-weight: bold;
                        margin-bottom: 8px;
                    }
                }
            }
            .hexa-secondary-btn{
                margin-top: 32px;
            }
        }
    }
    .governance-security-service-section-D{
        background: url('https://hexaviewtech.com/wp-content/uploads/2022/03/cloud-infrastructure.svg');
        background-repeat: no-repeat;
        background-size: cover;
        .security-service-inner{
            background-color: $primary-bgColor;
            color: #fff;
            padding: 90px 50px;
            margin-top: 70px;
            @media screen and (max-width: 899px) {
                margin-top: 0px;
            }
            h2, h4{
                color: #fff;
                margin-bottom: 10px;
                span{
                    color: $blue-Color;
                }
            }
            h3{
                font-weight: normal;
            }
            .hexa-default-btn{
                margin-top: 20px;
            }
        }
    }
}