@import '../../commonStyles/Common.scss';

.main-container {
    background-color: #ffffff;

    p {
        color: $primary-color;
    }

    .main-heading {
        display: flex;
        justify-content: center;
        align-items: center;

        .heading {
            position: relative;
            padding-top: 3rem;
        }

        .google-translate {
            position: absolute;
            right: 50px;
            width: 150px;
            .goog-logo-link{
                display: none;
            }

            .goog-te-gadget {
                color: transparent;
                cursor: not-allowed;
            }

            .goog-te-combo {
                width: 100%;
                background-color: #2297D6;
                color: #ffffff;
            }
        }

        h1 {
            color: $primary-color;
        }
    }

    .section {
        padding-bottom: 2rem;

        .text-section {
            .lists {
                padding-left: 1.8rem;

                li {
                    color: $primary-color;
                }
            }

            .colored-spans {
                color: $primary-color;
                font-weight: 600;
            }

            .spans {
                font-weight: 600;
                color: $primary-color;
            }
        }

        .heading {
            h4 {
                font-size: 20px;
                font-weight: 700;
                color: $primary-color;
            }
        }
    }
}