@import "../../../commonStyles/Common.scss";
.our-clients-section{
    background-color: #F5F7F9;
    
    .heading-elements{
        text-align: center;
        margin-bottom: 5%;
        h2{
            color: #000;
            font-weight: 600!important;
        }
        h4{
            margin-bottom: 6px;
            font-weight: 400 !important;
            font-size: 18px;
            color: #0D0101;
        }
    }
    .outerDiv{
        box-shadow: 35px 3px 36px #4D4A4A14;
        border-radius: 12px;
        padding: $SI-PX;
        .slick-slider{
            .slick-list{
                .slick-track{
                    .slick-slide{
                        .outerCard{
                            padding: 40px 45px 40px 25px;
                            margin: 0 24px;
                            .img{
                                transform: translateX(-60px);
                                img{
                                    width: 100%;
                                    // width: 250px;
                                    // height: 255px;
                                }
                            }
                            .content{
                                padding: 2em 4em 2em 0;
                                img{
                                    width: 40px;
                                    margin-bottom: 25px;
                                }
                                p{
                                    font-style: italic;
                                    margin-bottom: 30px;
                                    font-weight: 600;
                                }
                                h3{
                                    color: $secondary-color;
                                    font-weight: 600!important;
                                    margin-bottom: 8px;
                                }
                            }
                        }
                    }
                }
            }
            .slick-arrow{
                display: none!important;
            }
            .slick-dots{
                height: 100%;
                width: 30px;
                top: 0;
                right: 0;    right: 0;
                display: flex!important;
                align-items: center;
                flex-direction: column;
                justify-content: center;
                li{
                    background-color: #000;
                    width: 8px;
                    height: 8px;
                    border-radius: 5px;
                    margin: 5px 0;
                    button{
                        &::before{
                            display: none;
                        }
                    }
                }
                li{
                    &.slick-active{
                        height: 25px;
                        background: $secondary-color;
                    }
                }
            }
        }
    }
}