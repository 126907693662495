@import "../../commonStyles/Common.scss";
.slider{
    margin-top: -60px;
    .slick-slider{
        .slick-list{
            .slick-track{
                align-items: center;
                display: flex;
                .slick-slide{
                    .img{
                        padding: 24px;
                        img{
                            width: 100%;
                            max-height: 50px;
                        }
                    }
                }
            }
        }
    }
    .slick-arrow{
        display: none!important;
    }
}