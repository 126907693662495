@import '../../../commonStyles/Common.scss';

.sf-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
        
    }

    .sf-section-B {
        background:linear-gradient(to bottom,transparent, 
        rgba(255,255,255,0.4)),url('../../../../assets/pattern-bg-new.jpg');
        // url('../../../../assets/pattern-bg-new.jpg')
        padding-top: 5rem;

        .image-section {
            margin-top: 2rem;
        }


        .sf-B-card {
            background-color: #1492E6;
            display: flex;
            flex-direction: row;
            padding: 1rem;
            margin-bottom: 0.8rem;
            width: 96%;

            .heading,
            .text-section {
                h2,
                p {
                    color: #FFFFFF;
                }

            }

            .image-section {
                padding-left: 1rem;
                padding-right: 4rem;
                margin-top: 3rem;
                margin-right: 1rem;

                img {
                    width: 129px;
                    border-radius: 12px 12px 0px 0px;
                }
            }
        }
    }

    .sf-section {
        background:linear-gradient(to bottom, 
        rgba(255,255,255,0.7),white),url('../../../../assets/pattern-bg-new.jpg');
        padding-left: 20px;
        width: 98%;
        padding-bottom: 2rem;
        padding-left: 4rem;
        background-color: #FFFFFF;
        

        .sf-heading {
            padding-top: 22px;

            h3 {
                font-size: 26px;
                color: $primary-color ;
                font-weight: 600;
            }

            h4 {
                font-size: 18px;
                color: $primary-color ;
            }
        }

        p {
            font-size: 16px;
        }
    }

    .sf-section-C {
        background-color: #FFFFFF;
        padding-top: 0;

        .main-heading {
            display: flex;
            justify-content: center;
            align-items: center;

            h2 {
                font-size: 2.6rem;
                font-weight: 600;
                color: $primary-color;
            }
        }

        .css-185gdzj-MuiCardHeader-root {
            @include flexProp(center);
            margin-left: 1.5rem;
            border-radius: 50%;
            background-color: rgb(238, 245, 255);
            width: 5rem;
            height: 5rem;
            margin-top: 10px;
        }

        img {
            padding: 2px 0px 2px 14px;
            width: 4rem;
            height: 3rem;
        }


        .card {
            box-shadow: 0px 10px 35px -20px rgba(0, 0, 0, 0.16);

            @media (min-width: 900px),
            (max-width: 1100px) {
                min-height: 400px;
                max-height: 550px;
            }
        }

        .heading {
            h3 {
                font-weight: 600;
            }
        }

        .talk-to-epert {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            p {
                margin-top: 10px;
                padding-top: 4px;
                margin-right: 2px;
                font-size: 16px;
                cursor: pointer;
            }
        }
        .card-container{        
            width: 104%;
            #card-c-3{
                img{
                    height: 2.5rem;
                }
                @media (min-width: 900px),

                (max-width: 1100px) {

                    min-height: 350px;

                    max-height: 550px;

                }  
                    
            }
            #card-c-4{
                img{
                    height: 2.5rem;
                }
                    
            }

            #card-c-5 {
                min-height: 270px
            }
        }
        .card-c-6 {
            @include flexProp(center);
            box-shadow: 0px 10px 35px -20px rgba(0, 0, 0, 0.16);
            flex-direction: column;
            padding: 5.8rem 1.5rem 5.8rem 1.5rem;
        }
    }

    .sf-section-D {
        background-image: url('../../../../assets/salesforce/cricle-pattern-1.svg');
        background-repeat: no-repeat;
        background-position: 50% 10rem;
        background-color: $primary-bgColor;
        .counter-container{
            display: flex;
            justify-content: center;
            align-items: center;
        
            #counter-right {
                width: 28rem;
                                
            }

            .counter-box {
                padding: 0 50px 50px;
                
                .number-counter {
                    font-size: 4rem;
                }

                .counter-title {
                    padding-top: 30px;
                }
            }
        }  
    }

    .sf-section-E {
        background-color: #E2F1FF;


        .main-heading {
            @include flexProp(center);
            flex-direction: column;

            .line {
                border: 0.7px solid #2c2c2c;
                width: 11%;
            }

            h2 {
                color: $primary-color;
                font-size: 2.7rem;
            }
        }

        .card-styling {
            min-height: 220px;
            max-height: 250px;
            box-shadow: 0px 10px 35px -20px rgba(0, 0, 0, 0.16);

            h4 {
                font-size: 18px;
                font-weight: 600;
                color: $primary-color;
            }

            .text-icon-section {
                display: flex;
                flex-direction: row;
                padding: 1.5rem 0.5rem 1rem 1.5rem;

                p {
                    font-size: 13px;
                    padding-right: 10px;
                }

                .icon {
                    padding-right: 1rem;

                    img {
                        width: 3rem;
                    }
                }
            }
        }
    }
}