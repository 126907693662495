@import "../../../../../commonStyles/Common.scss";

.company-section {
    background-color: #F1F8FF;
    width: 100%;
    padding-left: 4rem;

    h2{
        font-size: 2.2rem;
        font-weight: 600 !important;
    }
    p{
        font-size: 18px;
        margin-bottom: 4rem;
    }
    img {
        width: 50%;
        // height: 50%;
    }

    .company-section-inner{
        .spacing{
            margin-top: 50px;
        }
    }
}