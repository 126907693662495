@import "../../../commonStyles/Common.scss";
.who-we-are-section{
    background: url('../../../../assets/texture-round.svg'), #005FFF;
    background-position: -27% -130%;
    background-size: 39%;
    background-repeat: no-repeat;
    .contentDiv{
        .heading-elements{
            h4{
                color: #fff;
                font-weight: 400 !important;
            }
            h2{
                color: #fff;
                font-weight: 600 !important;
            }
            h4,h2{
                margin-bottom: 0.3em;
            }
            p{      
                color: #fff;
            }
        }
        .officialPartner{
            color: #fff;
            margin: 35px 0;
            font-weight: 400 !important;
        }
        .partnerImage{
            display: flex;
            align-items: center;
            .img{
                padding: 16px;
                img{
                    width: 100%;
                }
               }
        }
    }
    .WhoWeAreImg{
        img{
            width: 100%;
        }
        .whoWeAreButton{
            text-align: right;
            .hexa-default-btn{
                margin: inherit;
                margin-right: 15%;
                p{
                    color: #fff;
                    img{
                        width: 34px;
                    }
                }
            }
        }

    }
}