@import "../../../../commonStyles/Common.scss";
.cloud-migration-solutions {
    // overflow: auto;
    .hybrid-cloud-computing-solution-section-B{
        .inn-container{
            justify-content: center;
            align-items: center;
        }
        .hybrid-cloud-computing-solution-section-B-left, .hybrid-cloud-computing-solution-section-B-right{
            padding: 5px;
        }
        .hybrid-cloud-computing-solution-section-B-right{
            h2{
                color: $primary-color;
                margin-bottom: 10px;
            }
            .text-section{
                font-size: $normal-text-font;
                color: $primary-color;
            }
        }
    
        }
        .hybrid-cloud-section-C{
            background-color: $primary-bgColor;
            .heading-wrapper{
                color: #fff;
                text-align: center;
                h2{
                    color: #fff;
                }
                h4{
                    color: $blue-Color;
                    margin: 10px 0;
                }
            }
            .service-wrapper-card{
                .MuiGrid-item{
                    &:nth-child(2){
                        margin-top: 50px;
                        border: none;
                    }
                    @media screen and (max-width: 899px) {
                        &:nth-child(2){
                            margin-top: 0px;
                        }
                      }
                }
                .card{
                    padding: 1.5em;
                    text-align: center;
                    background: #fff;
                    border-radius: 12px;
                    margin: 12px;
                    h4{
                        color: $primary-color;
                        margin: 15px 0 10px;
                        font-size: 20px;
                        font-weight: bold;
                    }
                }
                .text-section{
                    font-style: italic;
                    color: #fff;
                    margin: 40px 0 20px;
                }
            }
        }
        .hybrid-cloud-computing-solution-section-D{
            .hybrid-cloud-computing-solution-section-left{
                .heading{
                    h2{
                        margin-bottom: 10px;
                        line-height: 45px;
                        span{
                            color: $secondary-color;
                        }
                    }
                }
                .hexa-primary-btn{
                    margin-left: 0;
                    margin-top: 32px;
                }
            }
            .hybrid-cloud-computing-solution-section-right{
                .text-section{
                    border-radius: 12px;
                    background-color: $secondary-color;
                    padding: 1em;
                    color: #fff;
                    margin: 16px 0 16px auto;
                    &:nth-child(1){
                        width: 84%;
                        margin-top: 0;
                    }
                    &:nth-child(2){
                        width: 92%;
                    }
                    @media screen and (max-width: 899px) {
                        &:nth-child(1){
                            width: 100%;
                            margin-top: 0;
                        }
                        &:nth-child(2){
                            width: 100%;
                        }
                    }
                    h4{
                        color: #fff;
                        font-weight: bold;
                        font-size: 18px;
                        margin-bottom: 10px;
                    }
                    p{
                        margin-bottom: 0;
                        font-size: 0.85rem;
                    }
                }
  
            }
        }
}