@import "../../../commonStyles/Common.scss";

.alhexa-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .hexa-default-btn {
        margin-top: 3em;

        p {
            font-weight: bold;
            font-size: 18px;

            img {
                width: 40px;
            }
        }
    }

    .alhexa-section-B {
        background-color: $tertiary-bgColor;
        margin-top: 2em;

        .text-wrapper {
            color: $primary-color;

            h3,
            h2 {
                color: $primary-color;
                font-weight: bold;
            }
        }

        .cards-container {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;

            .cards-wrapper {
                @include flexProp(flex-start);
                flex-direction: column;
                margin-right: 1em;

                .card-chip-container {
                    margin-bottom: 1em;

                    .card-chip-elementory-column {
                        @include flexProp(flex-start);
                        background-color: #fff;
                        padding: 1.5em 1em 1.5em 1em;
                        border-radius: 15px;

                        p {
                            color: $secondary-color;
                            font-size: 15px;
                        }

                        .icon {
                            color: $secondary-color;
                        }
                    }

                    #first-el {
                        background-color: $secondary-color;

                        p,
                        .icon {
                            color: #fff;
                        }
                    }
                }
            }

            @media (min-width: 1200px) {
                .flex-end {
                    margin-top: 50px;
                }
            }
        }
    }

    .element-row {
        .text-wrapper {
            h2 {
                color: $primary-color;
                font-weight: bold;
            }

            p {
                color: $primary-color;
            }

            .lists-item {
                .icon {
                    color: #808285;
                    font-size: 15px;
                }

                ul {
                    li {
                        @include flexProp(flex-start);
                        line-height: 2;
                        color: #393a56;

                        span {
                            margin-left: 0.5em;
                        }
                    }
                }
            }

        }

    }

    .alhexa-section-D {
        background-color: $tertiary-bgColor;

        .text-wrapper {
            h2 {
                color: $primary-color;
                font-weight: bold;
            }

            p {
                color: $primary-color;
            }
        }

        .cards-container {
            display: flex;
            flex-wrap: wrap;

            .cards-wrapper {
                display: flex;
                flex-direction: column;
                width: 31%;
            }

            .card-chip-container {
                margin-bottom: 1em;
                margin-right: 1em;

                .card-chip-elementory-column {
                    @include flexProp(center);
                    flex-direction: column;
                    background-color: #fff;
                    padding: 1.5em 1em 1.5em 1em;
                    border-radius: 15px;

                    p {
                        color: $secondary-color;
                    }

                    .icon {
                        @include flexProp(center);
                        color: #fff;
                        width: 50px;
                        height: 50px;
                        border-radius: 50%;
                        border: 1px solid;
                        background-color: $secondary-color;
                        margin-bottom: 1em;
                    }
                }
            }

            @media (min-width: 1200px) {
                .first-element {
                    margin-top: 17em;
                }

                .second-element {
                    margin-top: 2em;
                }
            }

            @media (max-width: 760px) {
                .cards-wrapper {
                    width: 100%;
                }
            }
        }
    }

    .tech-stack-element-row {
        @include flexProp(flex-start);
        flex-wrap: wrap;
        background-color: $tertiary-bgColor;

        .heading-wrapper {
            text-align: center;
            margin-bottom: 2em;

            h2 {
                color: $primary-color;
                font-weight: bold;
            }

            .element-devider {
                display: flex;
                justify-content: center;

                .divider {
                    border-top: 1px solid $primary-color;
                    position: relative;
                    width: 100px;
                    display: block;
                }
            }
        }

        .card-withLeft-header-wrapper-element {
            background-color: #fff;
            margin-bottom: 1em;
            margin-right: 1em;

            .card .card-content {
                min-height: unset;
            }
        }

        #w-25 {
            width: 24%;
        }

        #w-50 {
            width: 45%;
        }

        @media (max-width: 760px) {
            #w-25 {
                width: 100%;
            }

            #w-50 {
                width: 100%;
            }
        }
    }

    .features-element-row {
        background-color: $primary-bgColor;
        padding: 1em;
        color: #fff;

        .background-overlay {
            background-image: url('https://hexaviewtech.com/wp-content/uploads/2021/08/cricle-pattern-1.svg');
            background-repeat: no-repeat;
            background-size: contain;
            background-position: 120% 0px;
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            opacity: 0.29;
        }

        .heading-wrapper {
            text-align: center;
            position: relative;
            color: #fff;

            h2 {
                color: #fff;
                font-weight: bold;
            }
        }

        .text-wrapper {
            position: relative;
            color: #fff;

            .lists-item {
                .card-withLeft-header-wrapper-element {
                    margin: 0;
                    padding: 0;
                    color: #fff;

                    .card .card-content {
                        min-height: unset;

                        h2,
                        h3,
                        h4,
                        p {
                            color: #fff;
                        }
                    }
                }
            }
        }
    }
}