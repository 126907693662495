@import "../../../commonStyles/Common.scss";

.cloud-elements-main-container {

    // overflow: auto;
    .section-B {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        background-color: #fff;
        margin-bottom: 2rem;

        .section-B-left{
            padding:15px;
            margin-top: 2em;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .section-B-right {
            padding: 2px;
            margin-top: 2em;
            .text-section {
                margin-bottom: 10px;
                font-size: $normal-text-font;
                color: $primary-color;

                .heading {
                    h2 {
                        font-size: 32px;
                        color: $primary-color;
                        margin-bottom: 0.2em;
                    }
                }

                p {
                    margin: 0;
                }
            }
        }

    }

    .section-C {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 2rem;
        background-color: $primary-bgColor;
        padding: 4rem 0 4rem 0;
        background-image: url('../../../../assets/salesforce/cricle-pattern-1.svg');
        background-repeat: no-repeat;
        background-position: 50% -35%;
        

        #counter-card-3 {
            .counter-box {
                width: 100%;
            }

        }

        .counter-widget-main-container {
            .counter-box {
                margin: 13px;

                .counter-box-elements .number-counter-wrapper {
                    font-size: 4.7em;
                    font-weight: 500;
                    margin-bottom: 0.3em;
                }
            }

            @media only screen and (max-width: 900px) {
                .counter-box {
                    margin-bottom: 3em;
                }
            }
        }
    }

    .section-D {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;
        padding: $S-P;
        background:linear-gradient(to bottom, 
        rgba(255,255,255,0.4),white),url('../../../../assets/pattern-bg-new.jpg');


        .section-D-inner-text-row {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-wrap: wrap;
            margin: 4rem 0rem 4rem 0rem;
            .text-1{
                .heading {
                    font-weight: bold;
                    color: $primary-color;
                }
            }

            .text-2 {
                margin-left: -2rem;
                p {
                    font-size: $normal-text-font;
                    color: $primary-color;
                }
            }
        }

        .cards-row {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            #card-c-1{
                @media (min-width: 900px),
                (max-width: 1100px) {
                    min-height: 300px;
                    max-height: 550px;
                }
            }

            img {
                width: 50%;
            }

            .card-container {
                .card {
                    border-radius: 0px 0px 20px 20px;
                    margin: 10px;
                    .card-content{
                        @media (min-width: 900px),
                        (max-width: 1100px) {
                            min-height: 270px;
                            max-height: 550px;
                        }                    }
                    
                }
            }
        }
    }
}