@import "../../../../commonStyles/Common.scss";

.rpa-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .rpa-section-B,
    .rpa-section-C {
        @include flexProp(center);
        flex-wrap: wrap;
        width: 100%;

        .text-section {

            h2,
            h3,
            h4 {
                color: $primary-color;
                font-weight: bold;

                span {
                    color: $secondary-color;
                }
            }

            p {
                margin-top: 1em;
                color: $primary-color;
                font-size: $normal-text-font;
            }
        }
    }

    .rpa-section-C {
        background-color: $tertiary-bgColor;

        .text-section {
            text-align: center;

            .pera {
                margin-left: 13em;
                margin-right: 13em;
            }
        }

        .card-wrapper {
            @include flexProp(center);
            flex-wrap: wrap;
            width: 100%;

            @mixin bgImg($bgclr, $url) {
                background-color: $bgclr;
                background-image: url($url);
                background-position: top right;
                background-repeat: no-repeat;
                background-size: 39% auto;
            }

            .card-container .card {
                padding: 2em;
                border-radius: 0;

                .card-content {
                    max-height: 22em;
                    min-height: 19em;
                    p{
                        font-size: 13px;
                    }
                }
            }

            .card-container {
                #first-card {
                    @include bgImg(#ffff, 'https://hexaviewtech.com/wp-content/uploads/2022/04/Robotic-Process-Automation.svg')
                }

                #second-card {
                    @include bgImg($primary-bgColor, 'https://hexaviewtech.com/wp-content/uploads/2022/04/RPA-Development-Services.svg');

                    .card-content {
                        color: #fff;
                    }
                }

                #third-card {
                    @include bgImg($secondary-color, 'https://hexaviewtech.com/wp-content/uploads/2022/04/Infrastructure-automation-support.svg');

                    .card-content {
                        color: #fff;
                    }
                }
            }

            .header-num {
                color: #4500C90D;
                font-size: 59px;
            }
        }

        .btn-wrapper {
            margin-top: 2em;
        }
    }

    .rpa-section-D {
        .elements-wrapper {
            .text-section {
                h2 {
                    color: #fff;
                    font-weight: bold;
                }

                p {
                    color: #fff;
                    margin-top: 1em;
                }
            }

            .btn-wrapper {
                padding: 3em;

                .hexa-primary-btn {
                    width: 215px;
                }
            }
        }

        .boxes-wrapper {
            @include flexProp(flex-start);
            flex-wrap: wrap;
            width: 100%;

            .box-container {
                background-color: #fff;
                padding: 1em;
                border-radius: 10px;
                width: 30%;
                margin-right: 1em;
                margin-bottom: 1em;

                .box {
                    .title {
                        color: $secondary-color;
                        font-size: 35px;
                        font-weight: bold;
                    }

                    .description {
                        font-size: 14px;
                        color: $primary-color;
                    }
                }
            }

            @media only screen and (max-width: 700px) {
                .box-container {
                    width: 100%;
                }
            }
        }
    }

    .rpa-section-E,
    .rpa-section-F {
        @include flexProp(center);
        flex-direction: column;
        width: 100%;
        background-color: #fff;
        .text-section {
            text-align: center;
            h2 {
                color: $secondary-color;
                font-weight: bold;
            }

            h3,
            h4 {
                color: $primary-color;
                font-weight: bold;
            }
        }

        .image-wrapper {
            max-width: 70%;
            margin: 0 auto;
        }
    }

    .rpa-section-F {
        background-color: $tertiary-bgColor;

        .text-section {
            h4 {
                font-size: 1.125rem;
            }
        }

        .cards-wrapper {
            @include flexProp(center);
            flex-wrap: wrap;
            width: 100%;
            margin-top: 1em;

            .card-chip-container {
                .card-chip-elementory-column {
                    box-shadow: none;
                    background-color: #fff;
                    margin-right: 1em;
                    margin-bottom: 1em;

                    img {
                        width: 10em;
                        height: 5em;
                    }
                }
            }
        }
    }

    .rpa-section-G {
        @include flexProp(center);
        flex-wrap: wrap;
        width: 100%;

        .text-section {

            h2,h3,
            h4 {
                color: $primary-color;
                font-weight: bold;
            }
        }

        .cards-wrapper {
            @include flexProp(center);
            flex-wrap: wrap;
            width: 100%;
            margin-top: 1em;

            .card-withLeft-header-wrapper-element {
                width: 48%;
                background-color: #fff;
                margin-right: 1em;
                margin-bottom: 1em;
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
                border-radius: 10px;

                .img-container{
                    img{
                        width: 3em;
                    }
                }

                .card-content{
                    min-height: 8em;
                    .card-content-heading {
                        h4 {
                            font-size: 18px;
                            font-weight: bold;
                            color: $primary-color;
                        }
                    }
                    p{
                        font-size: 15px !important;
                    }
                }
            }
        }
    }
}