@import "../../../../../commonStyles/Common.scss";
.devops-consulting-service{
    background: url('https://hexaviewtech.com/wp-content/uploads/2022/03/DevOps-expert-banner.svg');
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    .section-left{
        padding: 5px;        
        .heading{
            h3{
                font-weight: 700 !important;
                color: #fff;
            }
        }
        .text-section{
            margin-bottom: 5px;
            font-size: $normal-text-font;
            color: #fff;
            width: 110%;
        }
        .hexa-primary-btn{
            margin-left: 0;
            margin-top: 2em;
            background-color: #fff;
            p{
                color: #43baff;
                font-weight: 600;

                img{
                    width: 40px;
                }
            }
        }
    }

    }
