@import "../../../commonStyles/Common.scss";

@mixin flexProp($justifyContent) {
    display: flex;
    justify-content: $justifyContent;
    align-items: center;
}
.data-science-elements-main-container {
    h3 {
        color: $primary-color;
    }

    .data-science-section-B {
        width: 100%;
        background-color: #E2F1FF;
        .inn-container{
            justify-content: flex-start;
            align-items: center;
        }
        .left-section {
            @include flexProp(flex-start);
            flex-wrap: wrap;
            width: 100%;

            .counter-container {
                @include flexProp(center);
                flex-direction: column;
                margin-bottom: 1em;

                .text-wrap {
                    padding: 10px;
                }

                .number-conatiner {
                    @include flexProp(center);
                    font-size: 33px;
                    font-weight: bold;

                }

                .icon {
                    @include flexProp(center);
                    height: 60px;
                    width: 60px;
                    background: #fff;
                    border-radius: 50%;
                }

                .text-title {
                    font-size: 15px;
                    text-align: center;
                }
            }
        }

        .heading,
        .text-section {
            color: $primary-color;
        }

        .text-section {
            p {
                font-size: $normal-text-font;
                margin: 0;
                padding: 0;
            }
        }

    }

    .data-science-section-C {
        @include flexProp(center);
        flex-wrap: wrap;
        width: 100%;
        text-align: center;
        .heading{
            h2{
                margin-bottom: 0;
            }
        }
        .heading,
        .text-section {
            color: $primary-color;
        }

        .text-section {
            p {
                font-size: $normal-text-font;
                margin: 0;
                padding: 0;
            }
        }
    }

    .elements-row {
        width: 100%;
        padding-bottom: 1em;
        padding-top: 1em;
        .icon {
            @include flexProp(center);
            height: 100px;
            width: 100px;
            background: #EEF5FF;
            border-radius: 50%;

            img{
                width: 3em;
            }
        }

        .heading,
        .text-section {
            color: $primary-color;
        }
        .text-section{
            p{
                font-size: $normal-text-font;
            }
        }

        .check-marks {
            margin-top: 1em;
            .check-icon{
                font-size: 18px;
                margin-right: 0.5em;
            }
            ul {
                li {
                    @include flexProp(flex-start);
                    font-size: $normal-text-font;
                    color: $primary-color;
                }
            }
        }
    }

    .data-science-carouselsection-E {
        @include flexProp(flex-start);
        flex-wrap: wrap;
        width: 100%;
        background-color: $primary-bgColor;
        color: #fff;

        .heading-elements{
            margin-bottom: 3em;
            text-align: center;
            .heading{
                color: #fff;
                margin: 0;
                padding: 0;
            }
            .devider{
                @include flexProp(center);
                padding-top: 5px;
                padding-bottom: 5px;
                span{
                    width: 14em;
                    border-top: 2px solid #0B5070;
                }
            }
        }

        .info-container {
            width: 100% !important;
            padding: 1em;
            border: 2px solid #0B5070;
            border-radius: 20px;
            text-align: center;

            .info-text {
                color: #fff;
                font-size: 15px;
            }

            .avatar-container {
                @include flexProp(center);
                margin: 1em;

                .description {
                    margin-left: 1em;
                    text-align: left;

                    .name {
                        color: $secondary-color;
                        font-size: 1.2rem;
                    }

                    .title {
                        color: #fff;
                        font-size: 0.9rem;
                    }
                }
            }
        }

        .ant-carousel .slick-prev,
        .ant-carousel .slick-next,
        .ant-carousel .slick-prev:hover,
        .ant-carousel .slick-next:hover {
            font-size: inherit !important;
            color: #fff !important;
        }

        .ant-carousel .slick-prev,
        .ant-carousel .slick-next {

            color: #fff !important;
        }
    }
}
.recent-resources-section {
    .heading-wrapper {
        h2 {
            text-align: center;
            color: $primary-color;
        }
    }

    .cards-wrapper {
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        width: 100%;

        .card-container .card .card-content {
            min-height: 27em;
            max-height: 28em;
        }
        .card-container{
            .card{
                border-radius: 0;
            }
            #last-child{
                background-color: $primary-bgColor;
                color: #fff;
                h4,p,.card-content-heading{
                    color: #fff;
                }
            }
            #second-child{
                display: flex;
                flex-direction: column-reverse;
                background-color: #1B78FE;
                h4,p,.card-content-heading{
                    color: #fff;
                }
            }

        }
    }
    
}
.icon-box-container{
    @include flexProp(center);
    padding: 1em;
    background:#fff;
    margin-bottom: 1em;
    margin-right: 1em;
    border-radius: 10px;
    .icon-box-wrapper{
        @include flexProp(center);
        .icon{
            display: flex;
            align-items: center;
            margin-right: 0.5em;
        }
    }
}
