@import "../../../../../commonStyles/Common.scss";
.section-last{
    background-color: #f1f8ff;
    .inn-Container{
        align-items: center;
    }
    .getTouchBox{
        background: url('../../../../../../assets/cloud/texture-4.svg'), $primary-bgColor;
        color: #fff;
        padding:75px 60px;
        background-position: -420%;
        background-repeat: no-repeat;
        border-radius: 24px;
        width: 120%;
        position: relative;
        z-index: 9;
        @media screen and (max-width: 899px) {
            width: 100%;
            flex-basis: 100%;
          }
        h2{
            color: #fff;
            font-size: 25px;
            font-weight: 700 !important;
        }
        .text-section{
            margin-bottom: 50px;
        }

        .hexa-default-btn{
            p{
                color: #43baff;
                font-size: 16px;
                font-weight: 500;
            }
            img{
                width: 22%;
            }
        }
    }
    .CloudgetImg{
        img{
            width: 90%;
        }
    }
}