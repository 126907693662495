@import "../../../../commonStyles/Common.scss";
@mixin flexProp($justifyContent) {
    display: flex;
    justify-content: $justifyContent;
    align-items: center;
}
.cloud-migration-solutions {
    // overflow: auto;
    .cost-optimization-service-section-B{
        margin-bottom: 2rem;
        .container{
            margin-top: 2rem;
            width: 78rem;
            
        
        .cost-optimization-service-section-left{
            .heading{
                h2{
                    font-weight: 700;
                    color: $primary-color;
                }
            }
            .text-section{
                .check-marks{
                    ul{
                        margin-bottom: 0;
                        li{
                            display: flex;
                            align-items: center;
                            margin: 8px 0;
                            .icon{
                                margin-right: 5px;
                            }
                        }
                    }
                }
            }
            .hexa-default-btn{
                margin-left: 0;
                margin-top: 16px;
            }
        }
        .cost-optimization-service-section-right{
            padding-left: 16px;
            padding-top: 3rem;
            .bigBadge{
                background: #F1F8FF;
                padding: 4px 25px;
                border-radius: 25px;
                display: inline-block;
                margin-bottom: 24px;
                margin-left: 40px;
                @media screen and (max-width: 899px) {
                    margin-left: 0px;
                }
                p{
                    font-size: 18px;
                    margin-bottom: 0;
                    font-weight: 600;
                }
            }
            .text-section{
                border-left: 7px solid $blue-Color;
                padding: 2em 1em;
                margin: 16px 0 16px auto;
                box-shadow: 0 10px 20px 2px #e4e4e4;
                &:nth-child(2){
                    display: flex;
                    width: 90%;
                    margin-top: 0;
                    h4{
                        font-size: 45px;
                        margin-right: 5px;
                        font-weight: 600;

                    }
                }
                @media screen and (max-width: 899px) {
                    &:nth-child(2){
                        width: 100%;
                    }
                }
                h4{
                    color: $blue-Color;
                    font-weight: 600;
                    font-size: 24px;
                    margin-bottom: 2px;
                }
                p{
                    margin-bottom: 0;
                }
            }

        }
    }
    }
    .cost-optimization-service-section-C{
        background: url('../../../../../assets/cloud/cricle-pattern-1.svg'), $primary-bgColor;
        background-position: center -20%;
        background-repeat: no-repeat;
        width: 100%;
        .optimizationSection{
            @media (min-width: 900px) {
                min-height: 160px;
                max-height: 300px;
            }
            @include flexProp(center);
            background-color: #fff;
            position: relative;
            padding: 1em;
            border-radius: 12px;
            margin: 0 10px;
            .main-img{
                position: relative;
                margin-left: -35px;
                padding-bottom: 2rem;
                .img-container{
                    position: absolute;
                    margin-top: -5rem;
                }
                
            }
            .main-content{
                margin-left: 5px;
                padding: 2px;
            }
            .contact-container{
                padding-top: 20px;
            }
            .MuiGrid-container{
                align-items: center;
                .hexa-primary-btn{
                    margin-left: 0;
                    text-align: center;
                }
            }
            h4{
                color: $blue-Color;
            }
            .hexa-default-btn{
                font-weight: 400;
            }
        }

    }
    .cost-optimization-service-section-D{
        .heading-wrapper{
            h2{
                font-weight: bold;
                span{
                    color: $blue-Color;
                    font-weight: bold;
                }
            }
            h4{
                color: $primary-bgColor;
                margin: 10px 0;
                font-weight: bold;
            }p{
                margin-top: 1rem;
                line-height: 1.8rem;
            }
        }
        .service-wrapper-card{
            margin-top: 2rem;
            padding: 0px 1rem 0px 1rem;
            .outer-card{
                &:nth-child(1){
                    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
                    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
                }
                &:nth-child(2){
                    border-bottom: 0.5px solid rgba(0, 0, 0, 0.2);
                }
                &:nth-child(3){
                    border-right: 0.5px solid rgba(0, 0, 0, 0.2);
                }
                @media screen and (max-width: 899px) {
                    &:nth-child(1){
                        border: none;
                    }
                    &:nth-child(2){
                        border: none;
                    }
                    &:nth-child(3){
                        border: none;
                    }
                  }
                .content-wrapper{
                    .card-content{
                        p{
                            font-weight: 400;
                            margin-top: 5px;
                        }
                    }
                }
            }
            .card{
                margin-bottom: 0;
                padding: 0 1em;
                .card-content{
                    min-height: auto;
                    max-height: auto;
                }
            }
        }
    }
    .cost-optimization-service-section-E{
        background-color: $primary-bgColor;
        padding-top: 6rem;
        margin-bottom: 0;
        .cost-optimization-service-section-left{
            .heading{
                h2{
                    color: #fff;
                    margin-bottom: 10px;
                    font-weight: bold;
                    span{
                        color: $secondary-color;
                    }
                }
            }
            .text-section{
                p{
                    color: #fff;
                    width: 30rem;
                }
            }
            .hexa-default-btn{
                margin-left: 0;
                margin-top: 32px;
                p{
                    color: #43BAFF;
                    font-size: 16px;
                    font-weight: 600;
                }
                img{
                    width: 3rem;
                }
            }
        }

      
    }
    .cost-optimization-service-section-F{
        width: 100%;
        margin-top: 4rem;
        margin-bottom: 0;
        .carousel-container{
            @include flexProp(center)
        }
    }
    .cost-optimization-service-section-G{
        background-color: #F1F8FF;
        margin-bottom: 4rem ;
        .container{
            margin-top: -2rem;
            .getTouchBox{
                width: 100%;
                margin-left: 5rem;
                h2{
                    font-weight: bold;
                    font-size: 30px;
                }
                .hexa-default-btn{
                    p{
                        font-size: 18px;
                        img{
                            width: 3rem;
                        }
                    }
                }
            }

        }
    }
   

}