@import "../../../../commonStyles/Common.scss";

.dem-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .dem-section-B,
    .dem-section-C {
        @include flexProp(center);
        flex-wrap: wrap;
        width: 100%;

        .text-section {
            h2,
            h3,
            h4 {
                color: $primary-color;
                font-weight: bold;

                span {
                    color: $secondary-color;
                }
            }

            p {
                margin-top: 1em;
                color: $primary-color;
                font-size: $normal-text-font;
            }
        }
    }

    .dem-section-C {
        background-color: $tertiary-bgColor;
        .text-section {
            .list-item-wrapper {
                p{
                    font-weight: bold;
                }
                ul {
                    li {
                        @include flexProp(flex-start);
                        color: $primary-color;
                        font-size: $normal-text-font;

                        .css-ptiqhd-MuiSvgIcon-root {
                            fill: $secondary-color;
                            font-size: 15px;
                            margin-right: 0.5em;
                        }
                    }
                }
            }
        }
    }
    .dem-section-D{
        @include flexProp(center);
        flex-wrap: wrap;
        width: 100%;

        .elements-wrapper{
            .text-section{
                text-align: center;
                h2{
                    color: #fff;
                    font-weight: bold;
                }
                p{
                    color: #fff;
                    margin-top: 1em;
                }
            }
            .btn-wrapper{
                padding: 3em;
                .hexa-primary-btn{
                    width: 215px;
                }
            }
        }
    }
}