@import "../../commonStyles/Common.scss";
.section-A{
    height: 460px;
    padding: $S-P;
    .image-elements-section {
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-wrap: wrap;
        margin-top: 30px;

        .element-image-text-wrapper{
            width: 50%;
            padding: 10px;
            .heading-text{
                color: #fff;
                font-size: 2.625rem;
                font-weight: bold;
                margin: 0px;
            }
            .color-heading{
                color: #43BAFF;
                font-size: 2.625rem;
                font-weight: bold;
            }
            .text-section-a{
                font-size: 22px;
            }
            .text-section-b{
                font-size: $normal-text-font;
            }
            .text-section-a,.text-section-b{
                color: #fff;
                margin-bottom: 20px;
            }
        }    
        .talk-to-epert {
            display: flex;
            justify-content: flex-start;
            align-items: center;

            h5 {
                margin-top: 9px;
                margin-right: 10px;
                font-size: 16px;
                font-weight: bold;
                cursor: pointer;
            }
        }

        .form-element-section {
            position: relative;
            margin-top: 30px;
            width: 385px;
        }
        @media only screen and (max-width: 700px) {
            .form-element-section {
                width: 97% !important;
            }
            .element-image-text-wrapper{
                width: 100%;
            }
          }
    }
}
.contact-us-section{
    height: 460px;
    padding: $S-P;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    .img-background-overlay {
        background: $primary-bgOverlay;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
    }
    .element-image-text-wrapper{
        text-align: center;
        position: relative;
        .heading-text{
            color: #fff;
            font-size: 2.625rem;
            font-weight: bold;
            margin: 0px;
        }
        .color-heading{
            color: #43BAFF;
            font-size: 2.625rem;
            font-weight: bold;
        }
        .text-section-a{
            font-size: 22px;
        }
        .text-section-b{
            font-size: $normal-text-font;
        }
        .text-section-a,.text-section-b{
            color: #fff;
        }
    }    

}
@media only screen and (max-width: 700px) {
    .section-A,.contact-us-section{
        height: 100%;
    }
  }