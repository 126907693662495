@import "../../../commonStyles/Common.scss";

.main-wrapper-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .img-overlay {
        background: $primary-bgOverlay;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.9;
    }

    .section-A {
        padding: 4em;
    }

    .image-elements-section {
        .image-element-section {
            width: 30%;
        }

        .learn-more {
            width: fit-content;

            .learn-more-btn {
                @include flexProp(center);
                background-color: #fff;
                color: $primary-color;
                font-weight: bold;
                padding: 1.5em 3em 1.5em 3em;
                border-radius: 7px;
            }
        }
    }

    .section-B {
        width: 100%;

        .heading-wrapper {
            h2 {
                color: $primary-color;
                text-align: center;
                font-weight: bold;
            }
        }

        .cardes-wrapper {
            @include flexProp(center);
            flex-wrap: wrap;
            width: 100%;

            .card-chip-container {
                width: 48%;
                background-color: #F9F9F9;

                .card-chip-elementory-column {
                    box-shadow: none;

                    img {
                        width: 3em;
                    }

                    p {
                        color: #7A7A7A;
                        font-size: 1.25rem;
                        font-weight: bold;
                    }
                }
            }

            @media only screen and (max-width: 700px) {
                .card-chip-container {
                    width: 98%;
                }
            }
        }
    }

    .section-C {
        position: relative;

        .text-section {
            text-align: center;
            color: #fff;

            h2 {
                color: #fff;
                font-weight: bold;
            }
        }

        .container {
            @include flexProp(center);
        }
    }
    .section-D{
        .element-video-wrapper{
            .heading-wrapper{
                text-align: center;
                margin-bottom: 2em;
                h2{
                    color: $primary-color;
                    font-weight: bold;
                }
            }
            .iwrapper{
                position: relative;
                overflow: hidden;
                padding-top: 56.25%;
                margin-right: 2em;
                margin-bottom: 2em;

                iframe{
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    .section-E{
        .items-wrapper{
            display: flex;
            align-items: flex-start;
            // flex-wrap: wrap;
            img{
                width: 2em;
                margin-right: 1em;
                margin-top: 2px;
            }
            .text-wrapper{
                h3{
                    color: $primary-color;
                    font-weight: bold;
                }
                p{
                    color: $primary-color;
                }
            }

        }
        .image-wrapper{
            @include flexProp(center);
            height: 100%;
            width: 100%;
            border-radius: 20px;
            .text-wrapper{
                h2{
                    color: #fff;
                    font-weight: bold;
                }
            }
        }
    }
    .section-F {
        width: 100%;
        background-image: url('https://hexaviewtech.com/wp-content/uploads/2020/12/geometric-design-computer-1732847.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        position: relative;

        .heading-wrapper {
            position: relative;
            text-align: center;

            h2,
            h3 {
                color: #fff;
                font-weight: bold;
            }

            p {
                color: #fff;
                font-size: $normal-text-font;
            }
        }

        .cards-wrapper {
            position: relative;
            display: flex;
            flex-wrap: wrap;

            .card-chip-container {
                width: 24%;

                .card-chip-elementory-column {
                    box-shadow: none;

                    img {
                        width: 3em;
                        margin-bottom: 2em;
                    }

                    p {
                        color: #fff;
                        font-size: 1.75rem;
                    }
                }
            }
            @media (max-width: 768px){
                .card-chip-container{
                  width:100%;
                  max-width:100%
                }
              }
              @media (max-width: 992px) and (min-width: 760px){
                .card-chip-container{
                  width:50%;
                  max-width:100%
                }
              }
        }
    }
    .btn-container {
        @include flexProp(center);
        width: 100%;

        .btn {
            @include flexProp(center);
            background-color: $primary-bgColor;
            padding: 1.5em 3em 1.5em 3em;
        }

        .btn:hover {
            transform: scale(1.1);
        }
    }

}