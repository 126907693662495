@import "../../../commonStyles/Common.scss";
  .global-tech-series{
    .global-tech-series-B{
        background-color: #fff;
        .card{
            align-items: center;
            box-shadow: 0 7px 21px 2px #ccc;
            background: #fff;
            padding: 50px;
            .global-tech-inner-series-B{
                padding-left: 16px;
                text-align: center;
                .heading{
                    margin-bottom: 1em;
                    h2{
                        color: $primary-color;
                        font-weight: bold;
                    }
                    h4{
                        font-weight: bold;
                    }
                }
                .hexa-primary-btn{
                    margin-left: 0;
                    margin-top: 2em;
                }
            }
        }
    }
    .global-tech-series-C{
        background-image: url('https://hexaviewtech.com/wp-content/uploads/2021/08/pattern-bg-new.svg');
        background-repeat: no-repeat;
        background-size: cover;
        .global-tech-inner-series-C{
            .card{
                box-shadow: 0 7px 21px 2px #ccc;
                background: #fff;
                padding: 50px;
                .content{
                    h2{
                        font-weight: bold;
                        color: $primary-color;
                    }
                    h3{
                        color: $blue-Color;
                        font-weight: bold;
                    }
                }
                .image{
                    img{
                        width: 100%;
                    }
                }
            }
        }
    }
  }