@import "../../../commonStyles/Common.scss";

.pdf-scrapper-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .img-overlay {
        background: $primary-bgOverlay;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.9;
    }

    .section-A {
        padding: 4em;
    }

    .image-elements-section {
        .image-element-section {
            width: 30%;
        }
        .learn-more{
            width: fit-content;
            .learn-more-btn{
                @include flexProp(center);
                background-color: #fff;
                color: $primary-color;
                font-weight: bold;
                padding: 1.5em 3em 1.5em 3em;
                border-radius: 7px;
            }
        }
    }

    .pdf-scrapper-section-B {
        @include flexProp(flex-start);
        flex-wrap: wrap;
        width: 100%;

        .heading-wrapper {
            h2 {
                color: $primary-color;
                font-weight: bold;
            }
        }

        .card-container .card {
            margin-bottom: 0;
            margin-right: 0.7px;
            border-radius: 0;

            .card-content {
                @include flexProp(center);
                min-height: 16em;
                background-color: $primary-bgColor;
                color: #fff;
                font-weight: bold;
            }
        }

        #first-ch {
            border-top-left-radius: 60px;
        }

        #last-ch {
            border-bottom-right-radius: 60px;
        }
    }

    .pdf-scrapper-section-C {
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        background-image: url('https://hexaviewtech.com/wp-content/uploads/2020/12/architecture-modern-building-1048092.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;

        // background-color: $secondary-bgColor;
        .heading-wrapper {
            position: relative;

            h2 {
                color: #fff;
                font-size: 3rem;
                font-weight: bold;
            }

            .border-wrapper {
                .border {
                    border-bottom: 2px solid #fff;
                    width: 65%;
                    margin-bottom: 1em;
                }
            }
        }

        .text-elements-wrapper {
            position: relative;
            padding-left: 2em;
            padding-right: 2em;

            .text-setion {
                display: flex;

                h4 {
                    margin-bottom: 0;
                    color: #fff;
                    font-weight: bold;
                    font-size: 25px;
                }

                p {
                    color: #fff;
                    font-size: $normal-text-font;
                }

                .icon {
                    color: #fff;
                    margin-right: 0.5em;
                    margin-top: 3px;
                }
            }
        }
    }

    .pdf-scrapper-section-D {
        width: 100%;

        .heading-wrapper {
            h2 {
                color: $primary-color;
                font-weight: bold;
                text-align: center;
            }
        }

        .element-container {
            background-color: $primary-bgColor;
            color: #fff;
            padding: 2em;
            border-radius: 20px;

            .element-wrapper {

                h3,
                h2,
                h4,
                h5,
                p {
                    color: #fff;
                }
            }

            .card-content {
                min-height: 29em;
                max-height: fit-content;

                .card-content-heading {
                    font-weight: bold;
                    font-size: 19px;
                }

                .card-content-description {
                    margin-bottom: 1.5em;
                }

                ul {
                    li {
                        @include flexProp(flex-start);
                        font-size: $normal-text-font;
                        margin-bottom: 10px;

                        .icon {
                            margin-right: 0.5em;
                        }
                    }
                }
            }
        }

    }

    .pdf-scrapper-section-E {
        width: 100%;
        background-image: url('https://hexaviewtech.com/wp-content/uploads/2020/12/geometric-design-computer-1732847.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-attachment: fixed;
        position: relative;

        .heading-wrapper {
            position: relative;
            text-align: center;

            h2,
            h3 {
                color: #fff;
                font-weight: bold;
            }

            p {
                color: #fff;
                font-size: $normal-text-font;
            }
        }

        .cards-wrapper {
            position: relative;
            @include flexProp(center);
            flex-wrap: wrap;

            .card-chip-container {
                width: 33%;

                .card-chip-elementory-column {
                    box-shadow: none;

                    img {
                        width: auto;
                    }

                    p {
                        color: #fff;
                        font-size: 1.75rem;
                    }
                }
            }
        }
    }

    .btn-container {
        @include flexProp(center);
        width: 100%;

        .btn {
            @include flexProp(center);
            background-color: $primary-bgColor;
            padding: 1.5em 3em 1.5em 3em;
        }

        .btn:hover {
            transform: scale(1.1);
        }
    }
}