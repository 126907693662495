@import "../../../../commonStyles/Common.scss";

.ai-machine-learning-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .btn-container {
        @include flexProp(center);

        .btn {
            @include flexProp(center);
            height: 52px;
            background-color: #1B78FE;
            padding: 1em;
            border-radius: 40px;

            a {
                color: #fff;
                margin-right: 5px;
            }
        }
    }

    .ai-machine-services-section-B {
        text-align: center;

        .headings-element-wrapper {
            h2{
                font-size: 30px;
            }
            h4{
                font-size: 17px;
            }

            h2,
            h3,h4 {
                color: $primary-color;
            }
        }

        .text-wrapper {
            color: $primary-color;
            p{
                font-size: $normal-text-font;
            }
        }

        .cards-element-container {
            @include flexProp(center);
            padding: 1.5em;

            .card-container {
                margin: 0.5em;

                .card {
                    padding: 1em;
                }
            }
        }

        @media only screen and (max-width: 900px) {
            .cards-element-container {
                flex-wrap: wrap;
            }
        }
    }

    .ai-machine-services-section-C {
        width: 100%;
        padding-top: 1em;
        padding-bottom: 1em;
        background-color: $tertiary-bgColor;
        .inn-section{
            justify-content: flex-start;
            align-items: center;
        }
        .text-section {
            color: $primary-color;

            h2 {
                span{
                    color: $secondary-color;
                }
                color: $primary-color;
            }

            .text {
                p{
                    font-size: $normal-text-font;
                }
            }
        }
        .btn-container{
            justify-content: flex-start;
        }

        .img-container {
            text-align: center;
        }
    }

    .ai-machine-services-section-D {
        background-color: $primary-bgColor;

        .heading-wrapper {
            margin-left: 1em;

            h2 {
                color: #fff;

                span {
                    color: $secondary-color;
                }
            }
        }

        .service-wrapper-card {
            background-color: #fff;
            padding: 1em;
            border-radius: 10px;

            .card-container {
                padding-bottom: 0;
                .card {
                    margin-bottom: 2px;
                    .img-container{
                        img{
                            width: 3em;
                        }
                    }
                }
            }

            .card-content {
                min-height: 7em;
                max-height: 10em;
                .card-content-heading{
                    h4{
                        margin-bottom: 2px;
                    }
                }
            }

        }

        @media only screen and (min-width: 1150px) {
            .service-wrapper-card {
                position: absolute;
                left: -4em;
                right: 2em;
            }
        }
    }

    .ai-machine-services-section-E {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        width: 100%;

        .heading-wrapper {
            text-align: center;

            h2 {
                font-size: 2rem;
                color: $primary-color;
                font-weight: bold;

                span {
                    font-size: 35px;
                    color: $secondary-color;
                }
            }
        }

        .card-container .card .card-content {
            text-align: center;
            min-height: 22em;
            max-height: 24em;
        }

        .card {
            padding: 1em;
            margin: 0.5em;
            border-radius: 15px;
            .MuiCardHeader-avatar{
                width: 100%;
                height: 3em;
                justify-content: center;
                img{
                    width: 3em;
                }
            }
        }
    }

    .ai-machine-services-section-f {
        .heading-wrapper {
            margin-bottom: 2em;

            h2 {
                font-size: 2.3rem;
                color: #fff;
            }

            span {
                color: $secondary-color;
            }
        }

        .card-chip-section {
            @include flexProp(flex-start);
            flex-wrap: wrap;

            .card-chip-elementory-column {
                box-shadow: none;
                background: #fff;
                margin-right: 1em;
                margin-bottom: 1.5em;
            }
        }
    }

    .ai-machine-services-section-G {
        // @include flexProp(center);
        // flex-wrap: wrap;
        background-color: #fff;
        width: 100%;

        .heading-wrapper {
            text-align: center;
            margin-bottom: 3em;

            h2 {
                color: $primary-color;
                font-weight: bold;

                span {
                    color: $secondary-color;
                }
            }
        }

        .cards-wrapper {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            width: 100%;

            .card-withLeft-header-wrapper-element {
                background-color: $tertiary-bgColor;
                margin-bottom: 2em;
                border-radius: 10px;
                .card{
                    padding: 1em;
                    .img-container{
                        img{
                            width: 3rem;
                        }
                    }
                }

                .card-content {
                    min-height: fit-content;

                    .card-content-heading {
                        h4 {
                            font-size: 23px;
                            font-weight: bold;
                        }
                    }

                    ul {
                        * {
                            display: list-item;
                            list-style: disc;
                            margin-left: 1em;
                            font-size: $normal-text-font;
                        }
                    }
                }
            }
        }
    }

    .ai-machine-services-section-H {
        color: #fff;

        .heading-wrapper {
            color: #fff;

            h1 {
                font-size: 35px;
                color: #fff;
            }

            h4 {
                color: #fff;
            }
        }

        .check-marks {
            ul {
                li {
                    @include flexProp(flex-start);
                    font-size: $normal-text-font;
                }
            }
        }

    }
}