@import "../../../commonStyles/Common.scss";

.home-our-section-section{
    background: $primary-bgColor;
    .backgroundImg{
        .imgSection{
            width: 142%;
            margin-left: -42%;
            background-image: url('https://hexaviewtech.com/wp-content/uploads/2021/06/Group-301@2x.png');
            background-repeat: no-repeat;
            background-size: cover;
            height: 100%;
        }
    }
    .home-section-right{
        color: #fff;
        min-height: 650px;
        position: relative;
        .btnGroup{
            width: 48px;
            position: absolute;
            left: -48px;
            top: 50%;
            transform: translateY(-50%);
            button{
                width: 100%;
                background: #02174E;
                border:1px solid #092365;
                padding: 10px 0;
                .anticon{
                    color: #fff;
                }
            }
            button.disabled{
                .anticon{
                    color: #092365;
                }
            }
        }
        .home-section-inner-left{
                padding: 24px 0 0 24px;
                h4{
                    font-weight: 400 !important;
                    font-size: 16px;
                    color: #43BAFF;
                    margin: 6px 0 40px 16px;
                }
                .css-1gsv261{
                    border: none;
                    .MuiTabs-flexContainer{
                        display: block;
                        .customTab{
                            display: block;
                            h3{
                                color: $secondary-bgColor;
                                font-weight: bold;
                                font-size: 30px;
                                text-align: left;
                                span{
                                    font-size: 14px;
                                    margin-right: 4px;
                                    display: inline-block;
                                    transform: translateY(-12px);
                                }
                            }
                        }
                        .Mui-selected{
                            h3{
                                color: #fff;
                            }
                        }
                    }
    
                    .MuiTabs-indicator{
                        background: transparent!important;
                        height: 0!important;
                    }
                }
        }
        .home-section-inner-right{
            .main-inner-div{
                display: flex;
                flex-wrap: wrap;
                margin-top: 32px;
                .inner-div{
                    width: 33.3%;
                    flex-basis: 33.3%;
                    display: flex;
                    flex-wrap: wrap;
                    align-items: center;
                    border-right: 2px solid rgba(255, 255, 255, 0.05);
                    border-bottom: 2px solid rgba(255, 255, 255, 0.05);
                    padding: 1em;
                    position: relative;
                    &::after{
                        position: absolute;
                        content: '';
                        width: 12px;
                        height: 12px;
                        border-radius: 50%;
                        border:2px solid rgba(255, 255, 255, 0.05);
                        right: -6px;
                        bottom: -6px;
                        background: $primary-bgColor;
                        z-index: 9;
                    }
                    &:nth-child(3n){
                        border-right: none;
                        &::after{
                            display: none;
                        }
                    }
                    &:nth-last-child(1), &:nth-last-child(2), &:nth-last-child(3){
                        border-bottom: none;
                        &::after{
                            display: none;
                        }
                    }
                    .inner-img-div{
                        width: 30%;
                        flex-basis: 30%;
                        padding: 6px;
                        img{
                            width: 100%;
                        }
                    }
                    .inner-content-div{
                        width: 70%;
                        flex-basis: 70%;
                        padding-left: 0.5em;
                        p{
                            font-size: 14px;
                        }
                    }
                }
            }
            .hexa-default-btn{
                display: flex;
                justify-content: end;
                margin-right: 24px;
            }
        }
    }
}
.home-page{
    .home-our-service-section{
        .heading{
            margin-left: 2%;
            h4{
                color: #7B8085;
                span{
                    font-size: 20px;
                }
                
            }
            p{
                color: #7B8085;
                font-size: 18px;
            }
        }
    }
}

