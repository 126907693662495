@import "../../../../commonStyles/Common.scss";

.cloud-migration-solutions {

    // overflow: auto;
    .section-B {
        text-align: center;

        h2 {
            font-weight: 700;
            margin: 0;
            font-size: 1.8rem;

            span {
                color: $blue-Color;
            }
        }

        .text-section {
            margin-bottom: 15px;

            &.textBg {
                margin-top: -15px;
                margin-bottom: 35px;

                p {
                    background: #f1f8ff;
                    border-radius: 20px;
                    padding: 10px 25px;
                    margin: 0;
                    font-weight: 500;
                    display: inline;
                }
            }
        }

        .elementory-section {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 16px;

            .elementory-column {
                margin: 0.9%;
                box-shadow: 0 14px 22px -1px #ededed;
                border-radius: 6px;
                padding: 20px 15px 4px 15px;
                width: 14.8%;
                flex-basis: 14.8%;

                @media screen and (max-width: 1199px) {
                    width: 31.4%;
                    flex-basis: 31%;
                }

                @media screen and (max-width: 768px) {
                    width: 48.2%;
                    flex-basis: 48.2%;
                }

                img {
                    width: 60px;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 12px;
                    font-weight: bold;
                    color: $primary-color;
                }
            }
        }

        .hexa-primary-btn {
            border-radius: 30px;
            padding: 0.5rem 0.5rem;

            p {
                opacity: 1;
                font-weight: 400;
            }
        }
    }

    .section-C {
        background-color: #f1f8ff;
        margin-top: 40px;
        background-image: url('../../../../../assets/home/award-certificates.svg');
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 220%;

        .heading {
            padding-top: 1rem;

            h2 {
                font-weight: 300 !important;
            }

            h4 {
                font-size: 2rem;
                margin: 10px 0;
                color: $primary-bgColor;
            }

            p {
                font-size: 14px;
                font-weight: 700;
                color: $primary-color;
            }
        }


        .section-C-inner {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            @media (min-width: 1200px) {
                .cloud-text-section {
                    margin-top: -35px;
                }
            }

            .cloudMigrationImg {
                img {
                    width: 100%;
                }
            }

            .text-section {
                .flexSection {
                    display: flex;
                    flex-wrap: wrap;
                    margin-bottom: 10px;
                    margin-top: 1rem;

                    @media screen and (max-width: 899px) {
                        text-align: center;
                    }

                    img {
                        width: 40px;
                        margin: 0 auto;
                    }

                    h4 {
                        font-size: 16px;
                        font-weight: 600 !important;
                        color: $primary-color;
                    }

                    p {
                        font-size: 14px;
                        margin-bottom: 0;
                        opacity: 1;
                    }
                }
            }
        }

        .btn-section {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-top: 2.6rem;

            .hexa-secondary-btn {
                border-radius: 30px;
                padding: 0.2rem 2rem;
                width: fit-content;
                margin-bottom: 2rem;

                p {
                    opacity: 1;
                    font-weight: 600;
                }
            }
        }

    }

    .section-D {
        width: 100%;
        margin-top: 20px;

        .heading {
            text-align: center;

            h2 {
                font-size: 1.8rem;
                margin-bottom: 0px;

                &:nth-child(2) {
                    color: $blue-Color;
                }
            }

            p {
                padding-top: 12px;
                font-size: 20px;
            }
        }

        .section-D-inner {
            background: url('../../../../../assets/cloud/texture-4.svg'), $primary-bgColor;
            background-position: center -25%;
            background-repeat: no-repeat;
            height: 47%;
            margin-top: 30px;

            .cloudMigrationImg {
                position: relative;
                transform: translateY(-90px);

                img {
                    width: 105%;
                    height: 105%;
                }

                h3 {
                    color: #fff;
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                    font-size: 22px;
                    width: 80%;
                    line-height: 2rem;
                    font-weight: 600 !important;
                }

                @media (min-width: 600px) and (max-width: 990px) {
                    h3{
                        font-size: 18px;
                        line-height: 1rem;
                    }
                }
            }

        }
    }
    .gap-below-box{
        padding: 2rem;
    }
}