$primaryColor:#43BAFF;
$blue-color:#242a56;

.main-header-container{
    // align-items: center;
    // background-color: #F4F7FC;
    // box-shadow: -5px 11px 6px -10px rgba(0, 0, 0, 0.38);
    // display: flex;
    // height: 86px;
    // justify-content: space-between;
    // padding-left: 10px;
    // padding-right: 10px;
    // width: 100%;
    // z-index: 9;
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
    z-index: 9;
    background-color: transparent;
    .logo-container{
        height: 70px;
        display: flex;
        align-items: center;
        padding: 10px 0px 0 20px;
        img{
            width: 12em;
        }
    }
    .heder-left-panel{
        display: flex;
        justify-content: flex-end;
        padding-right: 20px;
        .menuButtonGroup{
            display: flex;
            align-items: center;
            height: 70px;
            padding: 5px 20px 5px 0px;
            position: relative;
            .menu{
                margin-bottom: 0;
                margin-right: 20px;
                &>li{
                    display: inline-block;
                    a{
                        text-decoration: none;
                        color: $blue-color;
                        display: flex;
                        align-items: center;
                        font-weight: 600;
                        font-size: 14px;
                        text-transform: uppercase;
                        padding: 10px;
                        transition: background-color 500ms ease-in-out;                       
                    }
                    &:hover{
                        a{
                            background-color: transparent;
                            color: $primaryColor;
                        }                        
                    }
                    
                    .submenu{
                        position: absolute;
                        left: -105px;
                        display: none;
                        background: #FFFFFF;
                        box-shadow: 0px 0px 16px #00000029;
                        width: 800px;
                        border-radius: 10px;
                        z-index: 9;
                        .home-section-inner-left{
                            padding: 20px 0;
                            .css-1gsv261{
                                border: none;
                                .MuiTabs-flexContainer{
                                    display: block;
                                    .customTab{
                                        display: block;
                                        padding: 0;
                                        min-height: 36px;
                                        width: 100%;
                                        padding: 10px 15px;
                                        -webkit-align-items: flex-start!important;
                                        align-items: flex-start!important;
                                        &:nth-child(1){
                                            .imgSection{
                                                background-color: #F27B6F;
                                            }
                                        }
                                        &:nth-child(2){
                                            .imgSection{
                                                background-color: #00B8F8;
                                            }
                                        }
                                        &:nth-child(3){
                                            .imgSection{
                                                background-color: #74C4BA;
                                            }
                                        }
                                        &:nth-child(4){
                                            .imgSection{
                                                background-color: #724E91;
                                            }
                                        }
                                        &:nth-child(5){
                                            .imgSection{
                                                background-color: #FEA04F;
                                            }
                                        }
                                        &:nth-child(6){
                                            .imgSection{
                                                background-color: #A0E394;
                                            }
                                        }
                                        .menuDiv{
                                            display: flex;
                                            align-items: center;
                                            width: 100%;
                                            .imgSection{
                                                width: 36px;
                                                border-radius: 4px;
                                                padding: 5px 6px;
                                                img{
                                                    width: 100%;
                                                }
                                            }
                                            .arrow{
                                                width: 12px;
                                                display: none;
                                                img{
                                                    width: 100%;
                                                }
                                            }
                                            .content{
                                                width: calc(100% - 40px);
                                                text-align: left;
                                                padding: 0 8px;
                                                h5{
                                                    margin-bottom:0px;
                                                    font-weight: 600!important;
                                                    font-size: 12px;
                                                }
                                                p{
                                                    margin: 0;
                                                    text-transform: capitalize;
                                                    font-size: 11px;
                                                    // font-weight: bold;
                                                    color: #242a56;
                                                }
                                            }
    
                                        }
                                    }
                                    .Mui-selected{
                                        background-color: #F2F6FA;
                                        .menuDiv{
                                            .arrow{
                                                display: block;
                                            }
                                        }
                                    }
                                }
                
                                .MuiTabs-indicator{
                                    background: transparent!important;
                                    height: 0!important;
                                }
                            
                            }
                        }
                        .home-section-inner-right{
                            .MuiBox-root{
                                padding: 0;
                                .middlePart{
                                    padding: 25px 15px;
                                    background-color: #F2F6FA;
                                    height: 100%;
                                    ul{
                                        li{
                                            a{
                                                font-size: 12px;
                                                margin: 6px 0;
                                                padding: 1px 0;
                                                color: #02174E;
                                                font-weight: 400;
                                                text-transform: initial;
                                                &:hover{
                                                    background-color: transparent;
                                                    color: #000;
                                                }
                                            }
                                            &:nth-child(1){
                                                opacity: 1;
                                                a{
                                                    font-size: 14px;
                                                    font-weight: bold;
                                                    margin-top: 0;
                                                    color: #005FFF;
                                                }
                                            }
                                        }
                                    }
                                }
                                .rightPart{
                                    padding: 25px 15px 0;
                                    h5{
                                        font-size: 13px;
                                        color: #527EB7;
                                        font-weight: bold;
                                        margin-bottom: 10px;
                                    }
                                    img{
                                        width: 100%;
                                        margin-bottom: 10px;
                                    }
                                    p{
                                        font-size: 14px;
                                        margin: 6px 0;
                                        color: #6C94C9;
                                        line-height: 1.3;
                                    }
                                    .hexa-default-btn{
                                        margin-top: 12px;
                                        display: block;
                                        text-align: left;
                                        p{
                                            margin: 0;
                                            padding:0;
                                            color: #005FFF;
                                        }
                                        img{
                                            width: 34px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    &:hover{
                        &>.submenu{
                            display: block;
                        }
                    }
                }
                &>a{
                    display: inline-block;
                }
            }
            .contact-us{
                background: #005FFF;
                border-radius: 20px;
                color: #fff;
                font-size: 12px;
                padding-right:18px;
                padding-left: 18px;
                height: 35px;
                border: #43BAFF;
                font-weight: 500;
            }
        }
        .certificate{
            width: 72px;
            img{
                width: 70%;
            }
        }
    }
}