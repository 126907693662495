@import "../../../../commonStyles/Common.scss";

.dv-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    .dv-section-B {
        @include flexProp(center);
        flex-wrap: wrap;
        width: 100%;
        background-color: $tertiary-bgColor;

        .heading-wrapper {

            h2,
            p {
                color: $primary-color;
                font-weight: bold;
                text-align: center;

                span {
                    color: $secondary-color;
                }
            }

            p {
                font-size: 1rem;
                padding-left: 8em;
                padding-right: 9em;
            }
        }
    }

    .dv-section-C,
    .dv-section-F {
        @include flexProp(center);
        flex-wrap: wrap;
        width: 100%;

        .text-section {

            h3,
            h4,
            h2 {
                color: $primary-color;
                font-weight: bold;

                span {
                    color: $secondary-color;
                }
            }

            p {
                font-size: $normal-text-font;
                color: $primary-color;
            }

            .border-div {
                display: flex;
                margin: 1em;
                width: -moz-fit-content;
                width: fit-content;
                padding: 0.7em;
                border-radius: 33px;
                background: #E2F1FF;
                font-weight: bold;
                color: $primary-color;
                font-size: $normal-text-font;
                opacity: 0.9;
            }

            .list-item-wrapper {
                ul {
                    li {
                        @include flexProp(flex-start);
                        color: $primary-color;
                        font-size: $normal-text-font;

                        .css-ptiqhd-MuiSvgIcon-root {
                            fill: $secondary-color;
                            font-size: 15px;
                            margin-right: 0.5em;
                        }
                    }
                }
            }
        }
    }

    .dv-section-D {
        background-color: $tertiary-bgColor;

        .text-section {
            text-align: center;

            h2 {
                color: $primary-color;
                font-weight: bold;

                span {
                    color: $secondary-color;
                }
            }

            p {
                font-size: $normal-text-font;
                color: $primary-color;
            }
        }

        .cards-chip-wrapper {
            @include flexProp(center);
            flex-wrap: wrap;
            width: 100%;
            margin-bottom: 2em;
            margin-top: 1em;

            .icon-box-container{
                .icon-box-wrapper{
                    .description{
                        font-size: 13px;
                        font-weight: bold;
                        color: $primary-color;
                    }
                }
            }
        }

        @media only screen and (min-width: 1000px) {
            .icon-box-container {
                width: 22%;
            }
        }
    }

    .dv-section-F {
        .card-wrapper {
            @include flexProp(center);
            flex-direction: column;
            flex-wrap: wrap;
            width: 100%;

            .card-withLeft-header-wrapper-element {
                background-color: #fff;

                .img-container {
                    width: fit-content;

                    img {
                        width: auto;
                        height: auto;
                    }
                }

                .card-content {
                    min-height: 6em;

                    .card-content-heading {
                        h4 {
                            font-size: 20px;
                            font-weight: bold;
                            color: $secondary-color;
                        }
                    }

                    p {
                        font-size: 15px !important;
                    }
                }

            }
        }

        .card-wrapper-2 {
            @include flexProp(space-evenly);
            flex-wrap: wrap;
        }
    }
}