@import "../../commonStyles/Common.scss";

@mixin flexProp($justifyContent) {
    display: flex;
    justify-content: $justifyContent;
    align-items: center;
}
.home-page{
    .css-1h9z7r5-MuiButtonBase-root-MuiTab-root{
        text-transform: none;
    }
    .home-section-B{
        background-color: #F4F7FC;
        .home-section-left{
            .heading{
                h4{
                    font-weight: 400 !important;
                    font-size: 18px;
                    margin-bottom: 5px;
                    color: #0D0101;
                }
                h2{
                    color: #0D0101;
                    font-size: 2rem;
                    font-weight: 600!important;
                }
            }
        }
        .home-section-right{
            display: flex;            
            .text-section{
                p{
                    font-weight: 400;
                }
            }
        }
        .home-section-right{
            display: flex;            
            .text-section{
                p{
                    font-weight: bold;
                }
            }
        }
        .home-section-right{
            .text-section{
                @media (min-width: 900px) {
                    padding-top: 30px;
                }
            }
        }
        .home-img-section{
            margin-top: 2em;
            box-shadow: 0px 3px 20px #00000029;
            .img-section{
                img{
                    width: 100%;
                }
            }
            .content-section{
                background-color: #fff;
                padding: 1em 0 1em 1em;
                display: flex;
                height: 100%;
                flex-direction: column;
                justify-content: center;
                h3{
                    font-size: 26px;
                    color: $primary-bgColor;
                    font-weight: 600!important;
                }
                p{
                    margin-bottom: 0px;
                    color: $primary-color;
                    font-weight: 400 !important;
                    span{
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }
            .blueHeading{
                h3{
                    color: $secondary-color;
                }
            }
        }
    }
    .home-our-service-section{
        padding: 4em 0 8em;
        .paddingX{
            padding: $SI-PX;
        }
        .heading{
            h4{
                font-weight: 400 !important;
                font-size: 18px;
                margin-bottom: 5px;
                color: #0D0101;
            }
            h2{
                font-size: 33px;
                font-weight: 600 !important;
                color: #070000;
            }
        }
        .home-our-service-section-inner-left{
            padding-top: 24px;
            padding-right: 20px;
            .css-1gsv261{
                border: none;
                .MuiTabs-flexContainer{
                    display: block;
                    border-right: 1px solid #eee;
                    .customTab{
                        display: flex;
                        color: #000;
                        font-size: 14px;
                        text-align: left;
                        width: 101%;
                        max-width: 101%;
                        .menuTab{
                            height: 100%;
                            width: 100%;
                            border-radius: inherit;
                            p{
                                margin-bottom: 0;
                                text-align: left;
                                display: flex;
                                align-items: center;
                                font-size: 16px;         
                                font-weight: 400;
                                img{
                                    height: 34px;
                                    margin-right: 16px;
                                    filter: grayscale(1);
                                }
                            }
                        }
                    }
                    .Mui-selected{
                        color: #1067FA;
                        border-right: 3.5px solid #1067FA;
                        background: #e9f1ff;
                        border-radius: 6px 0px 0px 6px;
                        .menuTab{
                            p{
                                img{
                                    filter: grayscale(0);
                                }
                            }
                        }
                    }
                }

                .MuiTabs-indicator{
                    background: transparent!important;
                    height: 0!important;
                }
            }
    }
    .home-our-service-section-inner-right{
        height: 100%;
        .MuiBox-root{
            padding: 24px 0 24px 24px;
            .service-img-content{
                .service-img{
                    width: 87%;
                    position: relative;
                    img{
                        width: 100%;
                    }
                    .service-card{
                        position: absolute;
                        z-index: 9;
                        background-color: #fff;
                        border-radius: 2px;
                        padding: 35px;
                        // box-shadow: 0 0 7px 3px #eee;
                        box-shadow: 0px 3px 6px #00000029;
                        width: 100%;
                        left: 17%;
                        top: 53%;
                        h3{
                            font-weight: bold;
                            font-size: 20px;
                        }
                        p{
                            margin-bottom: 0;
                        }
                        .btn-section{
                            text-align: right;
                           
                            img{
                                width:30px;
                            }
                        }
                    }
                }
            }
        }
   
    }
    }
    .home-section-C{
        background: $primary-bgColor;
        .backgroundImg{
            .imgSection{
                width: 142%;
                margin-left: -42%;
                background-image: url('https://hexaviewtech.com/wp-content/uploads/2021/06/Group-301@2x.png');
                background-repeat: no-repeat;
                background-size: cover;
                height: 100%;
            }
        }
        .home-section-right{
            color: #fff;
            .home-section-inner-left{
                    padding: 24px 0 0 24px;
                    h4{
                        font-weight: bold;
                        font-size: 20px;
                        color: $blue-Color;
                        margin: 0 0 40px 16px;
                    }
                    .css-1gsv261{
                        border: none;
                        .MuiTabs-flexContainer{
                            display: block;
                            .customTab{
                                display: block;
                                color: $secondary-bgColor;
                                font-weight: bold;
                                font-size: 32px;
                                text-align: left;
                            }
                            .Mui-selected{
                                color: #fff;
                            }
                        }
        
                        .MuiTabs-indicator{
                            background: transparent!important;
                            height: 0!important;
                        }
                    }
            }
            .home-section-inner-right{
                .main-inner-div{
                    display: flex;
                    flex-wrap: wrap;
                    margin-top: 32px;
                    .inner-div{
                        width: 33.3%;
                        flex-basis: 33.3%;
                        display: flex;
                        flex-wrap: wrap;
                        align-items: center;
                        border-right: 1px solid rgba(255, 255, 255, 0.2);
                        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
                        padding: 1em;
                        &:nth-child(3n){
                            border-right: none;
                        }
                        &:nth-last-child(1){
                            border-bottom: none;
                            border-right: none;
                        }
                        &:nth-last-child(2){
                            border-bottom: none;
                        }
                        &:nth-last-child(3){
                            border-bottom: none;
                        }
                        .inner-img-div{
                            width: 30%;
                            flex-basis: 30%;
                            padding: 6px;
                            img{
                                width: 100%;
                            }
                        }
                        .inner-content-div{
                            width: 70%;
                            flex-basis: 70%;
                            padding-left: 0.5em;
                            p{
                                font-size: 14px;
                            }
                        }
                    }
                }
                .hexa-default-btn{
                    display: flex;
                    justify-content: end;
                    margin-right: 24px;
                }
            }
        }
    }
 
   
}