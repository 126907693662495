@import "../../commonStyles/Common.scss";
.counter-widget-main-container{
    .counter-box{
        display: flex;
        position: relative;
        background: $secondary-bgColor;//#092365;
        color: #fff;
        margin:40px;
        position: relative;
        padding: 0 50px 20px;
        line-height: 20px;
        height: 100%;

        .counter-box-elements{
            .number-counter-wrapper{
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: -7px;
                font-size: 3rem;
                font-weight: 500;
            }
            .counter-title{
                font-size: 1.35rem;
                line-height: 33px;
                padding-top: 20px;
                text-align: center;
                box-sizing: border-box;
            }
        }

    }
    .counter-box::after{
        content: '';
        position: absolute;
        height: 82%;
        width: 6px;
        left: 0;
        top: 0;
        background: #43BAFF;
    }
}