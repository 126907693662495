@import "../../../../commonStyles/Common.scss";
.bic-main-container{
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }
    .bic-section-B{
        width: 100%;
        .text-wrapper{
            h2{
                color: $primary-color;
                font-weight: bold;
            }
            .text-sextion{
                p{
                    color: $primary-color;
                    font-size: $normal-text-font;
                }
            }
        }
    }
    .bic-section-C{
        width: 100%;
        background-color: $tertiary-bgColor;
        .heading{
            h2{
                color: $primary-color;
                font-weight: bold;
                text-align: center;
            }
        }
        .tech-wrapper{
            @include flexProp(center);
            flex-wrap: wrap;
            .card-chip-container{
                background-color: #fff;
                margin-right: 1em;
                margin-bottom: 1em;
                .card-chip-elementory-column{
                    box-shadow: none;
                    img{
                        width: 10em;
                    }
                }
            }
        }
    }
    .bic-section-D{
        width: 100%;
        background-color: $primary-bgColor;
        .heading{
            text-align: center;
            color: #fff;
            h2{
                color: #fff;
                font-weight: bold;
                text-align: center;
                span{
                    color: $secondary-color;
                }
            }
        }
        .service-partner{
            display: flex;
            flex-wrap: wrap;
            .trustImg{
                border-radius: 10px;
                text-align: center;
                background: #fff;
                margin: 10px;
                height: 80%;
                padding: 30px;
                display: flex;
                img{
                    width: 60%;
                    margin: auto;
                }
            }
        }
    }
    .bic-section-E{
        @include flexProp(flex-start);
        flex-wrap: wrap;
        background-color: $tertiary-bgColor;
        width: 100%;
        .heading{
            margin-bottom: 2em;
            h3{
                color: $primary-color;
                font-weight: bold;
                font-size: 30px;
                span{
                    color: $secondary-color;
                }
            }
        }
        .element-icon-wrapper{
            @include flexProp(flex-start);
            flex-wrap: wrap;
            margin-left: 2em;
            width: 100%;
            .boxes{
                @include flexProp(center);
            }
            @media only screen and (max-width: 700px) {
                .boxes {
                    flex-wrap: wrap;
                }
            }
        }
        .hexa-primary-btn{
            width: 197px;
            margin: 0;
            p{
                padding: 15px;
            }
        }
    }
    .bic-section-F{
        @include flexProp(center);
        flex-wrap: wrap;
        background-color: $primary-bgColor;
        width: 100%;
        .heading{
            h2,h4{
                color: #fff;
                font-weight: bold;
            }
            h4{
                margin-top: 1em;
                font-size: 1rem;
            }
        }
        .hexa-primary-btn{
            width: 340px;
            font-size: 16px;
            font-weight: bold;
            p{
                padding: 15px;
            }
        }

    }
}