@import '../../../../commonStyles/Common.scss';

.heading {

    h1,
    h2,
    h3 {
        color: $primary-color;
    }
}

.social-impact-container {
    .si-section-A {
        background-color: $primary-bgColor;
    }

    .si-section-B {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        text-align: center;

        .text-section {
            font-weight: bold;
            color: $secondary-bgColor;
        }

        .color-abb {
            background: #F2F5FF;
        }

        .color-bff {
            background-color: #b3d9ff;
        }

        .si-section-B-row {
            margin-top: $S-MT;
            display: flex;
            flex-wrap: wrap;

            .section-B-column {
                flex-direction: column;

                .heading{
                    padding-top: 1rem;
                }

                .image-section {
                    width: 400px;
                    height: 400px
                }

            }

            .text-section {
                font-weight: normal;
                text-align: left;
                padding: 0px 15px 30px 8px
            }
        }
    }

    .si-section-C {
        .main-heading{
            display: flex;
            justify-content: center;
            align-items: center;

            h2{
                font-size: 2.6rem;
                color: $primary-color;
            }
        }
        .si-elements-card-container {
            display: flex;
            flex-wrap: wrap;

            .card-styling {
                background-color: #7A7A7A;
                border-radius: 12px;
                padding: 1.2rem;

                .section-C-column {
                    .image-section {
                        img {
                            width: 508px;
                            height: 300px;
                        }
                    }

                    .heading,
                    .text-section {

                        h3,
                        p {
                            color: #ffffff;
                        }
                    }

                    .button-read-more {
                        background-color: #ffffff;

                        h5 {
                            color: $primary-color;
                        }

                    }
                }
            }

            .section-C-column {
                .text-section {
                    padding-top: 0.5rem;
                    margin-right: 10px;
                }

                .image-section {
                    img {
                        width: 550px;
                        height: 350px;
                        margin-bottom: 8px;
                    }
                }

                .button-read-more {
                    margin-bottom: $SI-MB;
                    width: fit-content;
                    border-radius: 12px;
                    background-color: $primary-bgColor;

                    h5 {
                        color: #ffffff;
                        padding: 15px 25px 15px 25px;
                    }

                }

                .button-read-more:hover {
                    background-color: #4169e1;
                }
            }
        }
    }
}