@import '../../../commonStyles/Common.scss';

.main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        justify-content: $justifyContent;
        align-items: center;
    }

    h4 {
        color: $primary-color;
    }

    .card {
        box-shadow: 0px 10px 20px 0px #999999;
        border-radius: 12px;

        .image-section {
            @include flexProp(center);
            margin-left: 1.5rem;
            border-radius: 50%;
            background-color: $primary-color;
            width: 5rem;
            height: 5rem;
            margin-top: 10px;

            img {
                padding: 2px;
                width: 3rem;
                height: 3rem;
            }
        }
    }

    .wm-section-A,
    .cm-section-B {
        background: url('../../../../assets/FinTech-wealth-management/pattern-bg-new.jpg');

        h3 {
            font-weight: 700 !important;
            color: $primary-color;
        }

        .wm-cards-section {
            @include flexProp(center);
            text-align: center;
            padding-top: 2.5rem;

            .card {
                @include flexProp(center);
                flex-direction: column;
            }

            h4 {
                font-size: 1.2rem;
            }
        }

        @media only screen and (max-width: 900px) {
            .wm-cards-section {
                flex-wrap: wrap;
            }
        }
    }

    .cm-section-B {
        background: #ffffff;

        .wm-cards-section {
            .card {
                box-shadow: 0px 10px 20px 0px rgba(0, 0, 0, 0.07);
            }
        }
    }

    .ds-section-A,
    .ds-section-B,
    .lending-section-B,
    .wn-section-B,
    .cm-section-C {
        background: $secondary-bgColor;

        .card {
            box-shadow: none;
        }

        h2 {
            font-size: 2.37rem;
        }

        .card-styling {
            border-radius: 8px;
            min-height: 250px;
            max-height: 250px;

            h4 {
                font-size: 1.8rem;
            }

            .text-icon-section {
                display: flex;
                flex-direction: row;
                padding: 1.5rem;

                .text-heading {

                    .text-section {
                        text-align: start;

                        p {
                            color: #000000;
                        }
                    }
                }

                .icon {
                    padding-right: 1rem;
                }
            }
        }

        .card-content-heading,
        .card-text-section,
        .list-section {
            padding-left: 1rem;
            padding-right: 1rem;
        }

        .container {
            .heading {
                h2 {
                    text-align: center;
                    color: #ffffff;
                    font-weight: 700;
                }

            }

            .text-section {
                text-align: center;

                p {
                    color: #ffffff;
                }
            }

            .card-wrapper {
                padding-top: 2.5rem;

                .card {
                    border-radius: 12px;
                    h4{
                        font-weight: 600 !important;
                    }
                }

                @media (min-width: 900px) {
                    .card {
                        min-height: 770px;
                        max-height: 770px;
                    }
                }
            }
        }
    }

    .lending-section-B {
        background: linear-gradient(to top, #ffffff 50%, $secondary-bgColor 50%);
        .container {
            .card {
                box-shadow: 1px 1px 1px 0px #999999;
            }

            .card-wrapper {
                @media (min-width: 900px) {
                    .card {
                        min-height: 700px;
                        max-height: 700px;
                    }
                }
            }
        }
    }

    .cm-section-B {
        background-color: #ffffff;

        .container {

            .heading,
            .text-section {
                text-align: center;

                p {
                    color: #000000;
                }

                h2 {
                    color: $primary-color;
                }
            }
        }
    }

    .cm-section-D {
        @include flexProp(center);
    }

    .wm-section-C,
    .cm-section-D {
        .container {
            h2 {
                color: $primary-color;
                font-weight: 600;
                width: 80%;
            }

            display: flex;
            flex-wrap: wrap;

            .talk-to-epert {
                @include flexProp(center);

                h5 {
                    margin-top: 9px;
                    margin-right: 10px;
                    font-size: 16px;
                    font-weight: bold;
                    cursor: pointer;
                }
            }
        }
    }

    .lending-section-A {
        background-color: #ffffff;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
    }

    .ds-section-A {
        background: url('../../../../assets/FinTech-wealth-management/OnDemandSolution/features-bg.png');
        background-position: center right;
        background-repeat: no-repeat;
        background-size: contain;

        .container {
            .text-section {
                p {
                    color: #000000;
                }

                text-align: start;
                width: 70%;
            }

            @media (max-width: 750px) {

                .card-left,
                .card-right {
                    max-height: 400px;
                    min-height: 225px;
                }
            }

            .card-left,
            .card-right {
                img {
                    width: 4em;
                }

                h4 {
                    font-weight: 700;
                    font-size: larger;
                    margin-left: -15px;
                }
            }

            .card-left {
                height: 135px;
            }

            .card-right {
                height: 155px;
                margin-top: 20px;
                box-shadow: 0px 10px 20px 0px #999999;
            }
        }

        .card-styling {
            width: 30%;
        }
    }
}