@import '../../../commonStyles/Common.scss';

.career-main-container {
    h2 {
        font-size: 2.7rem;
        font-weight: 600 !important;
    }

    h4,
    h5 {
        color: $primary-color;
    }

    h5 {
        font-size: 18px;
    }

    .career-section-B {
        background-color: #ffffff;

        .buttonWithNoBorder {
            border: none;
            display: inline-block;
            margin: 0 auto;
            text-align: center;

            p {
                color: $blue-Color;
                display: block;
                padding: 7px 0;
                cursor: pointer;

                img {
                    width: 30px;
                    color: #163582;
                }
            }
        }
    }

    .career-section-C {
        background-color: $secondary-bgColor;

        h3,
        h4 {
            color: #ffffff;
        }

        h3 {
            font-size: 38px;
        }

        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;
    }

    .career-section-D {
        background: url('../../../../assets/corporate-overview/overlay-shape.svg') ;
        .heading {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .common-card {
            padding: 1.5rem;
            border-radius: 18px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.15);

            .location {
                background-color: $tertiary-bgColor;
                background-color: #dbe7ff !important;
                width: fit-content;
                border-radius: 22px;

                p {
                    color: $secondary-color;
                    font-weight: bold;
                    padding: 5px 18px 5px 18px;
                }
            }

            .button {
                margin-top: -0.7rem;

                a {
                    font-size: 14px;
                    font-weight: 700 !important;
                    color: #163582;
                }

            }
        }

        .common-card:hover {
            box-shadow: 10px 10px 20px 0px #999999;
        }
    }
}