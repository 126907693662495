@import "../../../commonStyles/Common.scss";
.industries-section{
    background: url(../../../../assets/home/award-certificates.svg), #fff;
    background-position: 275% 10%;
    background-size: 80%;
    background-repeat: no-repeat;
    .heading-elements{
        margin-left: 1%;
        width: 86%;
        h4{
            margin-top: 50px;
            font-weight: 400 !important;
            font-size: 18px;
            margin-bottom: 5px;
            color: #0D0101;
        }
        h2{
            font-weight: 600!important;
            span{
                color: $secondary-color;
            }
        }  
    }
    .outerDiv{
        padding-right: 140px;
        .MuiGrid-item{
            &:nth-child(1){
                .card{
                    background-color: $blue-Color;
                    color: #fff;
                    h5{
                        color: #fff;
                    }
                }
            }
        }

        .card{
            background: #fff;
            padding: 1.5em;
            box-shadow: 0px 10px 40px #83838329;
            border-radius: 10px;
            margin: 10px;
            .card_header{
                display: flex;
                align-items: center;
                border-bottom: 1px solid #ccc;
                margin-bottom: 12px;
                padding-bottom: 12px;
                .img{
                    width: 35px;
                    margin-right: 10px;
                    img{
                        width: 35px;
                    }
                }
                .indHeading{
                    min-height: 35px;
                    display: flex;
                    align-items: center;
                    h5{
                        font-size: 16px;
                        font-weight: bold;
                        margin-bottom: 0;
                    }
                }
            }
            p{
                margin-bottom: 0;
                font-size: 13px;
            }
        }
    }
}