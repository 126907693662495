@import "../../../commonStyles/Common.scss";

.finTech-Solution-section {
    background: url(../../../../assets/home/polygon.png), url(../../../../assets/home/dottedCircle.png), #F4F7FC;
    background-position: 65% 25%, 104% 70%;
    background-size: 15%, 38%;
    background-repeat: no-repeat;
    padding: 193px 0 6px;
    margin-top: -60px;

    @media (max-width: 1200px) {
        margin-top: 0px;
    }

    .content {
        margin-top: -100px;
        padding-left: 10px;

        h1 {

            font-weight: bold;
            font-size: 2.5rem;

            span {
                color: $blue-Color;
            }
        }

        p {
            font-size: 17px;
            padding-bottom: 1rem;
            font-weight: 400;
        }
        .btn-section{
            .hexa-default-btn{
                p{
                    font-size: 16px;
                    font-weight: 500;
                    img{
                        width: 35px;
                    }
                }
            }
        }
    }

    .banner {
        padding-left: 100px;

        img {
            position: relative;
            width: 100%;
            margin-top: -18px;
        }

        .background-banner {
            position: absolute;
            background-image: url('../../../../assets/home/Polygon.svg');
            background-repeat: no-repeat;
        }
    }

}