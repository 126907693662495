@import '../../../../commonStyles/Common.scss';

.sustain-elements-main-container {

    .sustain-section-A {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: $S-MT;
    }

    .section-A-left {
        .h1 {
            font-size: 32px;
            color: $primary-color;
        }

        .text-section {
            font-size: $normal-text-font;
            color: $primary-color;

        }

        .join-network-button {
            width: 180px;
            height: 35px;
            padding-top: 16px;
            display: flex;
            justify-content: space-evenly;
            align-items: center;
            border-radius: 28px;
            background-color: #43BFFA;

            .button-image {
                margin-top: -10px;

                img {

                    width: 30px;
                    height: 30px
                }
            }
        }
    }

    .sustain-section-B {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        margin-top: $S-MT;
        background-color: $primary-bgColor;
        width: 100%;
        height: fit-content;
        padding: 4rem 0 4rem 0;

        .boxes {
            display: flex;
            flex-wrap: wrap;

            .boxes-main-container {

                .box {
                    background: $secondary-bgColor; //#092365;
                    color: #fff;
                    margin: 27px;
                    display: flex;
                    flex-direction: row;
                    padding: 20px 10px 10px 15px;
                    line-height: 20px;
                    height: 100%;
                    border-radius: 22px;

                    .box-image{
                        img{
                            width: 4rem;
                            padding-right: 1rem;
                        }
                    }
                    .text-section{
                        padding-top: 2rem;
                    }
                    @media (min-width: 900px) {
                        min-height: 150px;
                    }
                }
            }
        }
    }

    .sustain-section-C {
        background-color: #ffffff;

        h3{
            font-size: 24px;
            color: #000000;
        }
        h2{
            font-size: 2.3rem;
        }
    }

    .sustain-section-D {
        background-color: #E7F2F8;

        .main-heading {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .container {
            padding: 1.5rem;

            .section-D-common {
                display: flex;
                flex-wrap: wrap;
                flex-direction: row;
                justify-content: center;
                align-items: center;
            }
        }
    }
}