@import '../../../../commonStyles/Common.scss';

.di-main-container {
    @mixin flexProp($justifyContent) {
        display: flex;
        align-items: center;
    }

    .section-A .image-elements-section .element-image-text-wrapper .text-section-a {
        color: #49BAFF;
    }

    .di-section-B {
        background-color: #ffffff;

        .container {
            .element-inner-section {
                @media (min-width: 900px) and (max-width: 1000px) {
                    .image-section {
                        width: 10px;
                    }

                }
            }

            .heading {
                h2 {
                    color: #000000;
                }
            }
        }

        .btn-container {
            @include flexProp(center);

            .btn {
                @include flexProp(center);
                height: 52px;
                background-color: #1B78FE;
                padding: 1em;
                border-radius: 40px;

                a {
                    color: #fff;
                    margin-right: 5px;
                }
            }
        }
    }

    .di-section-C {
        background: #F2F5FF;

        .vertical-line {
            border-left: 6px solid #49BAFF;
        }

        .heading {
            h3 {
                font-weight: 900;
                color: $secondary-color;
            }
        }

        .text-section {
            font-style: italic;
            font-size: $normal-text-font;
        }
    }

    .di-section-D {
        .container {
            .element-inner-section {
                @media (max-width: 600px) {
                    .image-section {
                        img {
                            min-width: 100%;
                            max-width: 100%;
                        }
                    }

                }
            }
        }

        .card-section {
            margin-top: 0.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;

            h2 {
                padding: 1.5rem 0rem 0rem 0rem;
                font-weight: 600;
            }

            p {
                padding: 0rem 1rem 1rem 2rem;
            }
        }
    }

    .di-section-E {
        display: flex;
        flex-wrap: wrap;
        background: $primary-bgColor;

        .counters {
            .counter-card-styling {
                background-color: #0C2461E0;
                padding: 2rem;

                .heading {
                    h3 {
                        color: #49BAFF;
                    }

                }

                .number-array {
                    display: flex;
                    flex-direction: row;

                    .inner-array {
                        margin-right: 30px;
                    }

                    .heading {
                        display: flex;
                        justify-content: space-between;
                        border-bottom: 2px solid $secondary-color;
                    }
                }
            }
        }

        .heading {

            h1,
            h2,
            h3,
            h4 {
                color: #ffffff;
            }
        }

        .text-section {
            color: #ffffff;
        }
    }

    .di-section-F {
        display: flex;

        .upper-card {
            display: flex;
            justify-content: flex-end;

            .card-styling {
                width: 80%;
                padding: 0rem;
            }
        }

        .card-styling {
            background: #49BAFF;
            border-radius: 10px;
            padding: 0.7rem;

            .heading {
                padding: 0.5rem 0rem 0.5rem 0.5rem;

                h4 {
                    font-size: 1.2rem;
                    color: #ffffff;
                    font-weight: 600;
                }
            }

            .text-section {
                padding: 0rem 0.5rem 0.5rem 0.5rem;
                color: #ffffff;
            }
        }
    }

    .di-section-G {
        h2 {
            color: #000000;
        }

        .card-container {
            .card {
                margin-bottom: -90px;
            }
        }

        @media only screen and (max-width: 700px) {
            .card-container {
                .card {
                    margin-bottom: 0px;
                }
            }
        }
    }

    .di-section-H {
        background-color: #F9F9F9;

        h3 {
            color: #000000;
        }

        .container {
            .css-bhp9pd-MuiPaper-root-MuiCard-root {
                height: 150px;
            }
        }
    }

    .di-section-I {
        background: url("https://hexaviewtech.com/wp-content/uploads/2021/08/pattern-bg-new.svg");

        .ant-carousel .slick-next,
        .ant-carousel .slick-next:focus,
        .ant-carousel .slick-next:hover,
        .ant-carousel .slick-prev,
        .ant-carousel .slick-prev:focus,
        .ant-carousel .slick-prev:hover {
            color: black;
        }

        .heading {
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .carousel-display-row {
            display: flex;
            flex-direction: row;
        }

        .testimonial-card {
            padding: 2rem;

            .designation {
                font-size: 12px;
            }
        }
    }
}