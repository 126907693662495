@import "../../commonStyles/Common.scss";
.form-element-container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    opacity: 0.95;
    background-color: #fff;
    width: 100%;
    height: 100%;
    border-radius: 20px 20px 20px 20px;
    box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0,0,0,0.5);

    .form-element {
        padding: 40px 30px 0px 30px;
        .form-header{
            .heading-wrapper{
                h4{
                    color: #1B78FE;
                    font-size: 22px;
                    font-weight: bold;
                    text-align: center;
                }
            }
            .note-info{
                p{
                    font-size: 14px;
                    color: $primary-color;
                }
                .border-wrapper{
                    display: flex;
                    padding: 2px;
                    justify-content: center;
                    align-items: flex-start;
                    margin-bottom: 1em;
                    .border{
                        border: 1.5px solid #1B78FE;
                        width: 35%;
                    }
                }
            }
        }
        .form-element-items{
            .schedule-a-call{
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 17px;
                border-radius: 7px;
                margin-bottom: 1.5em;
            }
            .ant-input{
                border: none;
                border-bottom: 1px solid #ddd;
            }
            input::placeholder{
                color: #7A7A7A;
            }
            .ant-input:hover,.ant-input:focus {
                outline: none !important;
            }
            .ant-input-focused, .ant-input:focus{
                box-shadow: none;
            }
            .ant-col-16{
                max-width: 100%;
            }
        }
    }
}