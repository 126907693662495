@import "../../commonStyles/Common.scss";

.case-study-section {
    $primary-smallHeading-color:#7B8085;
    background-color: #fff;

    .heading-elements {
        text-align: center;

        h2 {
            color: $secondary-color;
            margin-bottom: 2px;
            font-weight: 600!important;
            font-size: 2rem;
        }
        p{
            font-size: 18px;
            margin-bottom: 0;
            color: $primary-smallHeading-color;
            font-weight: 400;
        }
    }

    .swiper-container {
        .swiper-wrapper {
            .swiper-slide {
                    margin-top: 32px;
                    .outerCard {
                        .card {
                            box-shadow: -2px -1px 17px 4px rgba(229, 229, 229, 0.74);

                            .img {
                                img {
                                    width: 100%;
                                }
                            }

                            .content {
                                padding: 16px 6px;
                                display: none;

                                h5 {
                                    margin-bottom: 14px;
                                }

                                h3 {
                                    color: $secondary-color;
                                    font-size: 20px;
                                    font-weight: 400 !important;
                                }

                                p {
                                    font-size: 13px;
                                    color: #01050D;
                                }
                            }
                        }
                    }
                }
                .swiper-slide-visible:not(.swiper-slide-active){
                    .img {
                        img {
                            height: 15em;
                        }
                    }
                }

                .swiper-slide-active {
                    margin-top: 0;
                    .outerCard {
                        padding: 1em;

                        .card {
                            padding: 16px;
                            border-radius: 8px;
                            .content {
                                display: block;
                            }
                        }
                    }
                }

            
        }
    }

    .btn-section {
        text-align: center;
        font-weight:500;
        p{
            img {
                width: 30px
            }

        }


    }



        .swiper-button-prev,
        .swiper-button-next {
            top: 78%;
            height: 35px;
            width: 35px;
            border:1px solid #005FFF;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after{
                font-size: 15px;
            }
        }

        .swiper-button-prev {
            left: 34%;
        }

        .swiper-button-next {
            right: 34%;
        }
}