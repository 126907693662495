@import "../../../../commonStyles/Common.scss";

.cloud-migration-solutions {

    // overflow: auto;
    .application-development-deployment-section-B {
        background-color: #fff;

        .application-development-deployment-section-left {
            padding-left: 20px;

            @media screen and (max-width: 899px) {
                padding-right: 0px;
            }

            .heading {
                h2 {
                    color: $secondary-color;
                    margin-bottom: 10px;
                    font-weight: bold;
                }

                h4 {
                    font-weight: bold;
                    color: $primary-bgColor;
                }
            }

            .text-section {
                p {
                    font-weight: 300;
                    opacity: 1;
                }
            }

            .img-section {
                display: flex;
                flex-wrap: wrap;
                margin-top: 16px;

                .img-section-inner {
                    width: 25%;
                    flex-basis: 33%;
                    background: #fff;
                    border-radius: 10px;
                    box-shadow: 0 12px 15px 0 #efefef;
                    margin: 0 0.5em;
                    padding: 1em 0.5em;
                    text-align: center;

                    @media screen and (max-width: 899px) {
                        width: 47%;
                        flex-basis: 47%;
                    }
                }
            }
        }

        .application-development-deployment-section-right {
            padding-right: 40px;
        }
    }

    .application-development-deployment-section-C {
        background: url('https://hexaviewtech.com/wp-content/uploads/2022/03/lets-talk-map.svg'), #F1F8FF;
        text-align: center;
        background-position: center;
        background-repeat: no-repeat;
        background-size: 50%;

        h2 {
            color: #000;
            font-weight: 600 !important;
            font-size: 2.2rem;
            margin: 0;
        }

        .text-section {
            p {
                margin: 0;
            }
        }

        .elementory-section {
            display: flex;
            flex-wrap: wrap;
            margin: 0 auto;
            width: 75%;

            .elementory-column {
                margin: 0.5%;
                border-radius: 6px;
                width: 24%;
                flex-basis: 24%;

                @media screen and (max-width: 768px) {
                    width: 49%;
                    flex-basis: 49%;
                }

                .counterMainDiv {
                    max-width: 100%;

                    .counter-widget-main-container {
                        .counter-box {
                            background: transparent;
                            border-radius: 12px;
                            padding: 1em;
                            margin: 10px;
                            line-height: normal;

                            &::after {
                                background: transparent;
                                height: 0;
                            }

                            .counter-box-elements {
                                text-align: center;
                                width: 100%;

                                .imgSection {

                                    img {
                                        width: 35%;
                                        margin: 0 auto 16px;
                                    }
                                }

                                .number-counter-wrapper {
                                    margin-top: 0;
                                    color: $primary-bgColor;
                                    font-weight: 500;
                                }

                                .counter-title {
                                    font-size: 20px;
                                    color: $primary-bgColor;
                                    line-height: 1.5rem;
                                    padding: 0;
                                }
                            }
                        }
                    }
                }

            }
        }
    }

    .application-development-deployment-section-D {
        background-color: $primary-bgColor;

        .heading {
            text-align: center;

            h3 {
                margin: 10px;
                color: #fff;
            }

            p {
                color: #fff;
                margin-bottom: 0;
            }
        }



        .tabpanel-container {
            display: flex;
            flex-direction: row;

            .tabpanel-img-section {
                img {
                    width: 80%;
                }
            }

            .tabpanel-text-section {
                width: 100%;

                .application-development-deployment-section-left {
                    padding-top: 4em;

                    .css-1gsv261 {
                        border: none;

                        .MuiTabs-flexContainer {
                            display: block;

                            .customTab {
                                display: block;

                                .text-section {
                                    text-align: left;
                                    text-transform: none;

                                    h3 {
                                        color: $blue-Color;
                                        font-weight: bold;
                                        font-size: 27px;
                                    }

                                    p {
                                        margin-top: -10px;
                                        color: $blue-Color;
                                    }
                                }
                            }

                            .Mui-selected {
                                .text-section {

                                    h3,
                                    p {
                                        color: #fff !important;
                                    }
                                }
                            }
                        }

                        .MuiTabs-indicator {
                            background: transparent;
                            height: 0 !important;
                        }
                    }
                }
            }
        }

        .hexa-primary-btn {
            border-radius: 30px;
            width: fit-content;
            padding: 0 1rem;

            p {
                opacity: 1;
                font-weight: 400;
                font-size: 18px;

                img{
                    width: 50px;
                }
            }
        }
    }
}